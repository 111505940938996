// Styles 
import './DropDownMenuItems.scss';

//Libraries
import { NavLink } from 'react-router-dom';

//Action
import { userConnexion, visibilityAuthPassword, changeInputAuthValue, } from '../../../../actions/authAction';
import { changeInputValue, saveWishlist } from '../../../../actions/userAction';
import { saveUserMedia, loadingMedia } from '../../../../actions/mediaAction';
import { countCart, saveCartInState, changeInputOrder,  } from '../../../../actions/cartAction';


//Hooks
import { useDispatch, useSelector } from 'react-redux';

const DropDownMenuItems = ({items, isOpenDropdown, keyId}) => {
    console.log(keyId);
    const dispatch = useDispatch();
    const isConnected = useSelector((state) => state.auth.userConnected);
    return (
        <div 
            className={ isOpenDropdown ? "dropdown-items-active" : "dropdown-items"}
           
        >
            {items.map(({title, slug}) => (
               <NavLink  to={`${slug}`} key={slug}> <li className="dropdown-item" >{title}</li> </NavLink>
            ))}
            {keyId === "prepage" && (
                <a rel="noreferrer" href="https://le-journal.artesane.com/artesane-formation-pro" target="_blank"><li className="dropdown-item">Espace formation pro</li></a>
            )}
            { isConnected && (keyId  === "user") && (
                <li 
                className="dropdown-item"
                onClick = {() => {
                    dispatch(changeInputValue(0, "userId"));
                        dispatch(changeInputAuthValue("", "email"));
                        dispatch(changeInputAuthValue("", "emailRegistration"));
                        dispatch(changeInputAuthValue("", "emailRegistration"));
                        dispatch(changeInputValue("", "lastNameAddressAdd"));
                        dispatch(changeInputValue("", "lastNameAddressAdd"));
                        dispatch(changeInputValue("", "firstNameAddressAdd"));
                        dispatch(changeInputValue("", "streetAddressAdd"));
                        dispatch(changeInputValue("", "complementAddressAdd"));
                        dispatch(changeInputValue("", "societyAddressAdd"));
                        dispatch(changeInputValue("", "postCodeAddressAdd"));
                        dispatch(changeInputValue("", "cityAddressAdd"));
                        dispatch(changeInputValue("", "telephoneAddressAdd"));
                        dispatch(changeInputValue(false, "defaultAdressAdd"));
                        dispatch(changeInputOrder(false, "addressValidate"));
                        dispatch(changeInputOrder(false, "errorTelephoneShipping"));
                        dispatch(changeInputOrder(0, "addressSelectCarnet"));
                        dispatch(changeInputValue([], "addresses"));


                        dispatch(changeInputOrder("", "firstNameShipping"));
                        dispatch(changeInputOrder("", "lastNameShipping"));
                        dispatch(changeInputOrder("", "societyShipping"));
                        dispatch(changeInputOrder("", "cityShipping"));
                        dispatch(changeInputOrder("", "complementShipping"));
                        dispatch(changeInputOrder("", "streetShipping"));
                        dispatch(changeInputOrder("", "postCodeShipping"));
                        dispatch(changeInputOrder("", "countryShipping"));
                        dispatch(changeInputOrder("", "telephoneShipping"));

                        dispatch(changeInputOrder("", "firstNameBilling"));
                        dispatch(changeInputOrder("", "lastNameBilling"));
                        dispatch(changeInputOrder("", "societyBilling"));
                        dispatch(changeInputOrder("", "cityBilling"));
                        dispatch(changeInputOrder("", "complement"));
                        dispatch(changeInputOrder("", "streetBilling"));
                        dispatch(changeInputOrder("", "postCodeBilling"));
                        dispatch(changeInputOrder("", "countryBilling"));
                        dispatch(changeInputOrder("", "telephoneBilling"));
                        dispatch(changeInputOrder("", "adjustmentsPromo"));   
                        dispatch(changeInputOrder("", "couponCode")); 
                        dispatch(changeInputOrder("", "adjustmentsShipping"));  
                        dispatch(visibilityAuthPassword(false));
                        dispatch(saveUserMedia("mediaArtsCulinaires", []));
                        dispatch(saveUserMedia("mediaArtsDuFil", []));
                        dispatch(saveUserMedia("mediaArtsPlastiques", []));
                        dispatch(saveCartInState([]));
                        dispatch(saveWishlist([]));
                        dispatch(countCart(0));
                        dispatch(loadingMedia(true));
                        sessionStorage.clear();
                        dispatch(userConnexion(false));
                    
                }}
             >
                 Me déconnecter
            </li> 
            )}

             
            
        </div>
    );
}

export default DropDownMenuItems;