
//API for get country's name from ISO code Alpha2 
import countries from "i18n-iso-countries";

//Style
import './Addresses.scss';
//Hooks
import { useSelector, useDispatch } from "react-redux";
//Action
import { changeInputValue, loadingAddAddress, postNewAddress } from '../../../actions/userAction';
//Components
import AddressCard from './AddressCard/AddressCard';
import InputSimple from '../InputSimple/inputSimple';
import ModifyAddress from './ModifyAddress/ModifyAddress';
import Spinner from "../../Commons/Spinner/Spinner";
import ErrorPopup from "../ErrorPopup/ErrorPopup";

//API for get country's name from ISO code Alpha2
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

const Addresses = () => {

   

    const dispatch = useDispatch();
    const firstName = useSelector((state) => state.user.firstNameAddressAdd);
    const lastName = useSelector((state) => state.user.lastNameAddressAdd);
    const society = useSelector((state) => state.user.societyAddressAdd);
    const street = useSelector((state) => state.user.streetAddressAdd);
    const complement = useSelector((state) => state.user.complementAddressAdd);
    const postCode = useSelector((state) => state.user.postCodeAddressAdd);
    const city = useSelector((state) => state.user.cityAddressAdd);
    const telephone = useSelector((state) => state.user.telephoneAddressAdd);
    const errorTelephone = useSelector((state) => state.user.errorPatternTelephoneAddressAdd);
    const country = useSelector((state) => state.user.countryAddressAdd);
    const defaultAddressAdd = useSelector((state) => state.user.defaultAdressAdd);
    const addresses = useSelector((state) => state.user.addresses);
    const defaultAddress = useSelector((state) => state.user.defaultAddress);
    const isOpenPopupAdress = useSelector((state) => state.user.isPopupModifyAddressOpen);
    const countries = useSelector((state) => state.user.countries);
    const loadingAddAddresses = useSelector((state) => state.user.loadingAddAddress);
    const loadingDeleteAddress = useSelector((state) => state.user.loadingDeleteAddress);

    return (
        <div className="addresses-wrapper">
            {isOpenPopupAdress && (
                <ModifyAddress />
            )}
            {(loadingAddAddresses || loadingDeleteAddress) && (
                <div className="spinner-add-address-spinner">
                    <Spinner />
                </div>
             )} 

            <div className="addresses-wrapper-top">
                <div className="addresses-wrapper-default">
                    { addresses.length > 0 && (

                        addresses.map(({id ,nom, last_name, first_name, company, street, city, complement, postcode, country_code, phone_number, par_defaut }) => {
                            if( par_defaut){
                            return (
                                <AddressCard
                                    key={id}
                                    id={id}
                                    title={"Adresse par défaut"}
                                    lastName={last_name}
                                    firstName={first_name}
                                    society={company}
                                    street={street}
                                    city={city}
                                    complement={complement}
                                    postCode={postcode}
                                    country={country_code}
                                    phoneNumber={phone_number}
                                />
                            );
                            }
                        })
                        // Object.keys(defaultAddress).length > 0 && (
                        //     <AddressCard
                        //             key={defaultAddress.id}
                        //             id={defaultAddress.id}
                        //             title={"Adresse par défaut"}
                        //             lastName={defaultAddress.last_name}
                        //             firstName={defaultAddress.first_name}
                        //             society={defaultAddress.company}
                        //             street={defaultAddress.street}
                        //             city={defaultAddress.city}
                        //             complement={defaultAddress.complement}
                        //             postCode={defaultAddress.postcode}
                        //             country={defaultAddress.country_code}
                        //             phoneNumber={defaultAddress.phone_number}
                        //     />
                        // )
                    )}
                    { addresses.length < 1 && (
                        <div className="address-null"> Vous n'avez pas encore d'adresses enregistrées.</div>
                    )}
                
                </div>
                <div className='addresses-form-add'>
                    <div className='address-form-title'>
                        Ajouter une adresse
                    </div>
                    <form
                        action="#"
                        onSubmit={(event) => {
                            event.preventDefault();
                            if((telephone.match(/[0-9]/g) || telephone.match(/[-+!?*$@%_]/g)) && (!telephone.match(/[A-Z]/g) && (!telephone.match(/[a-z]/g)))){
                                dispatch(changeInputValue(false, "errorPatternTelephoneAddressAdd"));
                                dispatch(postNewAddress());
                                dispatch(loadingAddAddress(true));
                            }else {
                                dispatch(changeInputValue(true, "errorPatternTelephoneAddressAdd"));
                            }
                         
                        }}
                    >
                        <div className='form-address-left'>
                            <InputSimple
                                inputName={"lastNameAdd"}
                                label={"Nom *"}
                                value={lastName}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "lastNameAddressAdd"));
                                }}
                            />

                            <InputSimple
                                inputName={"firstNameAdd"}
                                label={"Prénom *"}
                                value={firstName}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "firstNameAddressAdd"));
                                }}
                            />
                            <InputSimple
                                inputName={"societyAdd"}
                                label={"Société"}
                                value={society}
                                type={"text"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "societyAddressAdd"));
                                }}
                            />

                            <InputSimple
                                inputName={"streetAdd"}
                                label={"Voie et numéro *"}
                                maxLength={35}
                                value={street}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "streetAddressAdd"));
                                }}
                            />
                            <InputSimple
                                inputName={"complementAdd"}
                                label={"Complément"}
                                maxLength={35}
                                value={complement}
                                type={"text"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "complementAddressAdd"));
                                }}
                            />
                        </div>
                        <div className='form-address-right'>
                            <InputSimple
                                inputName={"postCodeAdd"}
                                label={"Code postal *"}
                                value={postCode}
                                type={country === "FR" ? "number" : "text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "postCodeAddressAdd"));
                                }}
                            />

                            <InputSimple
                                inputName={"cityAdd"}
                                label={"Ville *"}
                                value={city}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "cityAddressAdd"));
                                }}
                            />
                        <div className="input-wrapper">
                                <label className='personal-label' htmlFor="first-name">
                                    Pays *
                                </label>
                                <select
                                    required
                                    value={country}
                                    name="country"
                                    onChange={(event) => {
                                        dispatch(changeInputValue(event.target.value, "countryAddressAdd"))
                                    }}

                                >
                                    {countries.map(({name, code}) =>{
                                    
                                    if(code !== "EZ" && code !== "AC"){
                                    //  console.log(code);
                                        return (
                                            <option key={code} value={code}>{name}</option>
                                        )}
                                    })}
                                </select>
                            </div>
                            <InputSimple
                                error={errorTelephone}
                                inputName={"telephoneAdd"}
                                label={"Téléphone *"}
                                value={telephone}
                                type={"tel"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(changeInputValue(false, "errorPatternTelephoneAddressAdd"));
                                    dispatch(changeInputValue(event.target.value, "telephoneAddressAdd"));
                                }}
                            />
                             <div className={`input-information ${errorTelephone ? "error" : ""}` }> Les lettres ne sont pas autorisées.</div>
                            <InputSimple
                                className={"checkbox"}
                                inputName={"defaultAddressAdd"}
                                label={"Définir cette adresse par défaut"}
                                value={defaultAddressAdd}
                                type={"checkbox"}
                                required={false}
                                onChange={(event) => {
                                    
                                    dispatch(changeInputValue(!defaultAddressAdd, "defaultAdressAdd"));
                                }}
                            />
                        </div>
                        <button
                                className='submit-button'
                                type="submit"
                            >
                                Enregistrer
                            </button>
                    </form>
                </div>
            </div>
          
            <div className="addresses-wrapper-other">

                {addresses.length >  0 && (
                    addresses.map(({id , nom, last_name, first_name, company, street, city, complement, postcode, country_code, phone_number, par_defaut }) => {
                   if( !par_defaut){
                    return (
                        <AddressCard
                            key={id}
                            id={id}
                            title={"autre adresse"}
                            lastName={last_name}
                            firstName={first_name}
                            society={company}
                            street={street}
                            city={city}
                            complement={complement}
                            postCode={postcode}
                            country={country_code}
                            phoneNumber={phone_number}
                        />
                      );
                    }
                }))}
                
            </div>
          
        </div>
    );
}

export default Addresses