export const FETCH_LESSONS = 'FETCH_LESSONS';
export const SAVE_CATALOGUE_LESSONS_IN_STATE = 'SAVE_CATALOGUE_LESSONS_IN_STATE';
export const TOGGLE_IS_FILTER_ACTIF = 'TOGGLE_IS_FILTER_ACTIF';
export const TOGGLE_FILTER_NAME_ACTIF = 'TOGGLE_FILTER_NAME_ACTIF';
export const TOGGLE_IS_SORT_ACTIF = 'TOGGLE_IS_SORT_ACTIF';
export const SAVE_NUMBER_TOTAL_LESSONS_IN_STATE = 'SAVE_NUMBER_TOTAL_LESSONS_IN_STATE';
export const SAVE_CURRENT_PAGE_CATALOGUE = 'SAVE_CURRENT_PAGE_CATALOGUE';
export const FETCH_FILTERS = 'FETCH_FILTERS';
export const SAVE_FILTERS = 'SAVE_FILTERS';
export const TOGGLE_NUMBER_FILTERS_VISIBLE = 'TOGGLE_NUMBER_FILTERS_VISIBLE';
export const ADD_FILTERS_CHECKBOX = 'ADD_FILTERS_CHECKBOX';
export const REMOVE_FILTERS_CHECKBOX = 'REMOVE_FILTERS_CHECKBOX'; 
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const CHANGE_SORT_ACTIF_VALUE = 'CHANGE_SORT_ACTIF_VALUE';
export const CHANGE_SORT_TABLE = 'CHANGE_SORT_TABLE ';
export const CHANGE_PRICE_SLIDER_VALUE = 'CHANGE_PRICE_SLIDER_VALUE';
export const TOGGLE_404 = 'TOGGLE_404';
export const FETCH_ALL_STOCK_VARIANTS = "FETCH_ALL_STOCK_VARIANTS";
export const SAVE_PRODUCT_VARIANTS = "SAVE_PRODUCT_VARIANTS";
export const SAVE_NUMBER_PRODUCT = "SAVE_NUMBER_PRODUCT";



export const saveNumberProduct = (newValue) => ({
    type: SAVE_NUMBER_PRODUCT,
    value: newValue,
});

export const saveProductVariants = (newValue) => ({
    type: SAVE_PRODUCT_VARIANTS,
    value: newValue,
});


export const fetchAllStocksVariants = () => ({
    type: FETCH_ALL_STOCK_VARIANTS,

});

export const toggle404 = (newValue) => ({
    type: TOGGLE_404,
    value: newValue,
});

export const changePriceSliderValue = ( newValue ) => ({
    type: CHANGE_PRICE_SLIDER_VALUE,
    value: newValue, 
});

export const changeSortTable = ( newValue ) => ({
    type: CHANGE_SORT_TABLE,
    value: newValue,
});

export const changeSortActifValue = ( newValue ) => ({
    type: CHANGE_SORT_ACTIF_VALUE,
    value: newValue,
});

export const toggleLoading = (newValue) => ({
    type: TOGGLE_LOADING,
    value: newValue, 
});

export const removeAllFilters = () => ({
    type: REMOVE_ALL_FILTERS,
});

export const removeFiltersCheckbox = (newValue, filterName) => ({
    type: REMOVE_FILTERS_CHECKBOX,
    value: newValue,
    name: filterName,
});

export const addFiltersCheckbox = (newValue, filterName) => ({
    type: ADD_FILTERS_CHECKBOX,
    value: newValue,
    name: filterName,
});

export const toggleNumberFiltersVisible = (newValue) => ({
    type: TOGGLE_NUMBER_FILTERS_VISIBLE,
    value: newValue,
});


export const fetchLessons = (newValue) => ({
    type: FETCH_LESSONS,
    value: newValue,
});

export const fetchFilters = (universId) => ({
    type : FETCH_FILTERS,
    value: universId,
});

export const saveFilters = (newValue, filtreObject) => ({
    type: SAVE_FILTERS,
    value: newValue,
    object: filtreObject,
});

export const saveCatalogueLessonsInState = (newValue, numberOfItems) => ({
    type: SAVE_CATALOGUE_LESSONS_IN_STATE,
    value: newValue,
    number : numberOfItems,
});

export const saveNumberTotalLessonsInState = (newValue) => ({
    type: SAVE_NUMBER_TOTAL_LESSONS_IN_STATE,
    value: newValue,
});

export const saveCurrentPageCatalogue = (newValue) => ({
    type: SAVE_CURRENT_PAGE_CATALOGUE,
    value: newValue,
});

export const toggleIsFilterActif = (newValue) => ({
    type: TOGGLE_IS_FILTER_ACTIF,
    value: newValue,
});

export const toggleIsSortActif = (newValue) => ({
    type: TOGGLE_IS_SORT_ACTIF,
    value: newValue,
});

export const toggleFilterNameActif = (filterName,stateElement ) => ({
    type: TOGGLE_FILTER_NAME_ACTIF,
    name: filterName,
    state: stateElement,
})