
export const SAVE_LOCATION_ACTIF = 'SAVE_LOCATION_ACTIF';
export const SAVE_FAQS_DATA_IN_STATE = 'SAVE_FAQS_DATA_IN_STATE';
export const FETCH_FAQS = 'FETCH_FAQS';
export const TOGGLE_DROPDOWN_USER = 'TOGGLE_DROPDOWN_USER';
export const TOGGLE_DROPDOWN_LESSON = 'TOGGLE_DROPDOWN_LESSON';
export const TOGGLE_ACTIF = 'TOGGLE_ACTIF';
export const TOGGLE_ACCORDION = 'TOGGLE_ACCORDION';
export const TOGGLE_SCROLL = 'TOGGLE_SCROLL';
export const CHANGE_SEARCH_BAR_HOME= 'CHANGE_SEARCH_BAR_HOME';
export const CHANGE_INPUT_NEWSLETTER= 'CHANGE_INPUT_NEWSLETTER';
export const FETCH_PROFESSORS = 'FETCH_PROFESSORS';
export const SAVE_HOME_DATA_IN_STATE = 'SAVE_HOME_DATA_IN_STATE';
export const TOGGLE_BURGER_MENU = 'TOGGLE_BURGER_MENU';
export const TOGGLE_TAXONS_ACTIF = 'TOGGLE_TAXONS_ACTIF';
export const TOGGLE_UNDER_TAXONS_ACTIF = 'TOGGLE_UNDER_TAXONS_ACTIF';
export const SAVE_HOME_UNIVERS_LOCATION_ACTIF = 'SAVE_HOME_UNIVERS_LOCATIO_ACTIF'; 
export const TOGGLE_SOUL_BUTTON_VOIR_PLUS = 'TOGGLE_SOUL_BUTTON_VOIR_PLUS';
export const FETCH_MEGA_MENU = "FETCH_MEGA_MENU";
export const SAVE_MEGA_MENU = "SAVE_MEGA_MENU";
export const TOGGLE_MEGA_MENU_ACTIF = "TOGGLE_MEGA_MENU_ACTIF";
export const IS_LOADING_MEGA_MENU = 'IS_LOADING_MEGA_MENU';
export const TOP_SLIDER_CHANGE_INDEX = 'TOP_SLIDER_CHANGE_INDEX';
export const LESSON_SLIDER_CHANGE_INDEX = "LESSON_SLIDER_CHANGE_INDEX";
export const FETCH_LESSONS_SLIDER = " FETCH_LESSONS_SLIDER";
export const SAVE_LESSONS_SLIDER = "SAVE_LESSONS_SLIDER";
export const FETCH_TOP_SLIDER_ITEMS = "FETCH_TOP_SLIDER_ITEMS";
export const SAVE_TOP_SLIDER_ITEMS = "SAVE_TOP_SLIDER_ITEMS";






export const saveTopSliderItems = (newValue) => ({
    type: SAVE_TOP_SLIDER_ITEMS,
    value: newValue,
});

export const fetchTopSliderItems = (newValue) => ({
    type: FETCH_TOP_SLIDER_ITEMS,
    value: newValue,
});

export const fetchLessonsSlider = (newValue) => ({
    type : FETCH_LESSONS_SLIDER,
    value: newValue,
});

export const saveLessonsSlider = (newValue) => ({
    type :SAVE_LESSONS_SLIDER,
    value: newValue
});

export const lessonSliderChangeIndex = (newValue) => ({
    type: LESSON_SLIDER_CHANGE_INDEX,
    value: newValue,
});


export const topSliderChangeIndex = (newValue) => ({
    type: TOP_SLIDER_CHANGE_INDEX,
    value: newValue,
});


export const isLoadingMegaMenu = (newValue) => ({
    type: IS_LOADING_MEGA_MENU,
    value: newValue,
});

export const toggleMegaMenuActif = (newValue) => ({
    type: TOGGLE_MEGA_MENU_ACTIF,
    value: newValue,
});

export const saveMegaMenu = (newValue, tableau) => ({
    type: SAVE_MEGA_MENU,
    value: newValue,
    tableau: tableau,
});


export const fetchMegaMenu = (univers, currentFilters) => ({
    type: FETCH_MEGA_MENU,
    value: univers,
    filters: currentFilters,
});

export const saveHomeUniversLocationActif = (newValue) => ({
    type: SAVE_HOME_UNIVERS_LOCATION_ACTIF,
    value: newValue,
});


//Soul
export const toggleSoulButtonVoirPlus = (newValue) => ({
    type: TOGGLE_SOUL_BUTTON_VOIR_PLUS,
    value : newValue,
});


//MenuBurger
export const toggleMenuBurger = (newValue) => ({
    type: TOGGLE_BURGER_MENU,
    value: newValue,
});


export const toggleTaxonsActif = (newValue) => ({
    type: TOGGLE_TAXONS_ACTIF,
    value: newValue

});

export const toggleUnderTaxonsActif = (newValue) => ({
    type: TOGGLE_UNDER_TAXONS_ACTIF,
    value: newValue

});


//Data : utilisée pour sauvegarder les faqs, 
export const saveHomeDataInState = (stateProperty, newValue) => ({
    type: SAVE_HOME_DATA_IN_STATE,
    value: newValue,
    property: stateProperty,
});

//Professors
export const fetchProfessors = (idUnivers) => ({
    type: FETCH_PROFESSORS,
    id: idUnivers,
});

//Faq
export const fetchFaqs = () => ({
    type: FETCH_FAQS, 
});

export const saveFaqsDataInState = (newValue) => ({
    type: SAVE_FAQS_DATA_IN_STATE,
    value: newValue,
});

export const toggleAccordion = (newValue, newNumber) => ({
    type: TOGGLE_ACCORDION,
    value: newValue,
    number: newNumber,
});

//NavLogo and LessonSlider
export const toggleActif = (newValue) => ({
    type: TOGGLE_ACTIF,
    value: newValue
})

//NavBarMutual
export const toggleDropownUser = (newValue) => ({
    type: TOGGLE_DROPDOWN_USER,
    value: newValue,
});

export const toggleDropownLesson = (newValue) => ({
    type: TOGGLE_DROPDOWN_LESSON,
    value: newValue,
});


//Header
export const toggleScroll = (newValue) => ({
    type: TOGGLE_SCROLL,
    value: newValue,
});


//UnderNavBar
export const changeSearchBarHome = (newValue) => ({
    type: CHANGE_SEARCH_BAR_HOME,
    value: newValue,
});



//Newsletter
export const changeInputNewsletter = (newValue) => ({
    type: CHANGE_INPUT_NEWSLETTER,
    value: newValue,
});

