// Components
import Logo from '../../Commons/Logo/Logo';
import DropDownMenu from '../../Commons/DropDownMenu/DropDownMenu';
import NavBar from './NavBar/NavBar';

//Hooks
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from 'react-router-dom';
//Actions
import { toggleDropown, toggleBurger } from "../../../actions/prepageAction";

//Styles
import './Header.scss';

//Data
import { dropdownFirstHome } from '../../../data/dropdownFirstHome';

const Header = () => {
    
    const location = useLocation();
    const dispatch = useDispatch();
    const isOpenDropdown = useSelector((state) => state.prepage.dropDownMenuFirstHome);
    const isOpenBurger = useSelector((state) => state.prepage.toggleBurger );

    useEffect(()=> {
        dispatch(toggleBurger(false));
    }, [dispatch, location]);
    


    return (
        <header
            className="header"
            onMouseEnter={() => {
                dispatch(toggleDropown(false));
            }}
        >
            <div className='dropDown-menu-wrapper'>
            <DropDownMenu firstItem={'Nos cours'} secondItem={"v"}navItems={dropdownFirstHome} keyId={"prepage"} action={toggleDropown} isOpen={isOpenDropdown} />
            </div>
            {/* <div className='burger-prepage-menu-wrapper'> */}
                <div className={isOpenBurger ? "prepage-menu-responsive-burger-open": "prepage-menu-responsive-burger"}> 
                    <div
                    onClick={() =>  {
                        dispatch(toggleBurger(!isOpenBurger));
                    }}
                    >
                        <div className={ isOpenBurger ? "burger-item-one" : "burger-menu-cross-item"}></div>
                        <div className={ isOpenBurger ? "burger-item-two" : "burger-menu-cross-item"}></div>
                        <div className={ isOpenBurger ? "burger-item-three" : "burger-menu-cross-item"}></div>
                    </div>  
                 </div>
            {/* </div> */}
            <div className={isOpenBurger ? "prepage-menu-wrapper" : "prepage-menu-wrapper-closed"}>
                <div className="prepage-menu-item">
                    <NavLink
                        to={"/arts-du-fil"}
                    >
                        Arts du fil
                    </NavLink>
                </div>
                <div className="prepage-menu-item">
                    <NavLink
                        to={"/arts-culinaires"}
                    >
                        Arts culinaires
                    </NavLink>
                </div>
                <div className="prepage-menu-item">
                    <NavLink
                        to={"/arts-plastiques"}
                    >
                        Arts plastiques
                    </NavLink>
                </div>
                <div className="prepage-menu-item">
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://le-journal.artesane.com/artesane-formation-pro"
                    >
                        Espace formation pro
                    </a>
                </div>
            </div>
            <NavLink to={"/"}>
                    <Logo />
            </NavLink>
            <NavBar />  
        </header>
    );
}

export default Header; 