//Styles
import './NavIcons.scss';
import { NavLink } from 'react-router-dom';

const NavIcons = ({logo, path, numberOfProduct, styleColor, altPicture, title}) => {

    
    return(
        <div className='navIcon-wrapper'>
        {/* <NavLink to={path}> */}
            <div className='navicon-logo-wrapper'>
                <img className='navIconLogo' src={logo} alt={altPicture} />
                {numberOfProduct > 0 && (
                    <span className={`product-number ${styleColor}`}> {numberOfProduct}</span>
                )}
            </div>
            <div className='navIcon-title'>
                {title}
            </div>
            {/* </NavLink> */}
        </div>
    )};

export default NavIcons;