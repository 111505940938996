//Libraries
import axios from 'axios';
import Cookies from 'universal-cookie';

//Algo filtre
import { Artesane_Filtre, artesaneFiltre_calculerEtatDesProduits, artesaneTriChoix } from '../selectors/ArteseFilter';




//Actions
import { 
        FETCH_FAQS,
        saveHomeDataInState,
        FETCH_PROFESSORS,
        FETCH_MEGA_MENU,
        saveMegaMenu,
        isLoadingMegaMenu,
        FETCH_LESSONS_SLIDER,
        saveLessonsSlider,
        FETCH_TOP_SLIDER_ITEMS,
        saveTopSliderItems,
        } from "../actions/homeActions";

import {
        FETCH_LESSONS,
        saveCatalogueLessonsInState,
        toggleLoading,
        saveFilters,
        removeAllFilters,
        addFiltersCheckbox,
        toggle404,
        FETCH_ALL_STOCK_VARIANTS,
        saveProductVariants,
        } from "../actions/catalogueAction";

import {
    FETCH_COURS_IDEAL,
    FETCH_MAINTENANCE_MODE,
    FETCH_PRODUCTS_COURS_IDEAL,
    GET_GENERAL_SEARCH_BAR,
    GET_UNIVERS_SEARCH_BAR,
        saveCommonDataInState
        } from "../actions/commonAction";

import {
         FETCH_PRODUCT_DATA,
         saveProductData,
         isLoadingProduct,
        } from '../actions/productsActions';
    
import {
            FETCH_PROFESSEUR_CATALOGUE_DATA,
            saveProfesseurCatalogueData,
            FETCH_PROFESSEUR_PUCLIC_DATA,
            saveProfesseurPublicData,
            FETCH_ALL_PROFESSORS,
            saveAllProfessors,
        } from '../actions/professorsAction';

import { 
            FETCH_USER_DATA,
            changeInputValue,
            saveOrders,
            FETCH_ORDER_ITEMS,
            saveOrderItems,
            loadingOrderItems,
            saveWishlist,
            PUT_MODIFY_ADDRESS,
            toggleModifyAddress,
            loadingModifyAddress,
            FETCH_COUNTRIES,
            saveCountries,
            errorModifyAddress,
            POST_NEW_ADDRESS,
            loadingAddAddress,
            togglePopupError,
            DELETE_ADDRESS,
            loadingDeleteAddress,
            PATCH_MY_PROFIL_DATA,
            loadingUserRequest,
            checkSave,
            fetchUserData,
            PATCH_MY_PERSONAL_INFOS,
            PATCH_SUBSCRIPTIONS,
            PUT_MODIFY_PASSWORD,
            loadingModifyPassword,
            DEFAULT_ADDRESS,
            putWishlistItem,
            PUT_WISHLIST_ITEM,
            wishlistItem,
            POST_MAILCHIMP_NEWSLETTER,
            postMailChimpNewsletter,
           
        } from '../actions/userAction';

import {
            POST_EMAIL_VERIFICATION,
            isEmailExist,
            POST_CONNEXION,
            userConnexion,
            changeInputAuthValue,
            loadingConnexion,
            errorConnexion,
            POST_REGISTRATION,
            loadingRegistration,
            POST_MAIL_CONFIRMATION,
            postMailConfirmation,
            RESET_PASSWORD,
            MODIFY_RESET_PASSWORD,
         
        } from "../actions/authAction";

import { artesaneFiltre_changerPlusieursEtats } from '../selectors/ArteseFilter';
import { FETCH_COURSE_ITEM, FETCH_SINGLE_COLLECTION, FETCH_USER_MEDIA, loadingCollection, loadingCourseItem, loadingMedia, saveCourseItem, saveSingleCollection, saveUserMedia, saveTimeVideo, PATCH_USER_TIME_IN_VIDEO,  } from '../actions/mediaAction';
import { ADD_ORDER_ITEM_CONNECTED, CHANGE_QUANTITY, countCart, CREATE_CART_CONNECTED, CREATE_CART_DISCONNECTED, DELETE_ORDER_ITEM_CART, getCartConnected, getCartDisconnected, GET_CART_CONNECTED, GET_CART_DISCONNECTED, loadingAddCart, loadingDeleteModifyCart, saveCartInState, toggleVariantsChoice, verifyCart, VERIFY_CART, changeInputOrder, FETCH_PRODUCT_CART_ASSOCIATION, saveCartAssociation, fetchProductCartAssociation, PUT_ADD_ADDRESS_ORDER, FETCH_PAYMENT_URL, FETCH_PAYPLUG_REUSSITE, PATCH_SHIPPING_METHOD, POST_COUPON_CODE, FETCH_ADJUSTMENTS, fetchAdjustments, FETCH_METHODES_ENVOIE, DELETE_COUPON_CODE } from '../actions/cartAction';
import { CoPresent } from '@mui/icons-material';




const apiMiddleware = (store) => (next) => (action) => {

    // "https://api.artefact.mobi.com/api/v2/"
    const apiBaseUrl =  'https://api.artesane.com/api/v2/';
                        // 'http://www.artesane.com:8000';
                        // 'http://192.168.100.30:8000';
    const apiJson =  'https://catalogue.artesane.com';
                        //'http://www.artesane.com:8001';
                    // 'http://192.168.100.30:8001'


        if(action.type === DELETE_COUPON_CODE) {
            const customerId = store.getState().user.customerId;
            const orderId = store.getState().cart.cartData.id;
            axios.post(`${apiBaseUrl}shop/orders/${orderId}/coupon`, {
                "order_id": orderId,
                "code_coupon": "__SUPPRESSION__",
            }, {
                headers : {
                    'Content-Type': 'application/ld+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }
            })
            .then((response) => {
                // console.log(response);
                store.dispatch(changeInputOrder("", "couponCode"));
                store.dispatch(changeInputOrder(0, "orderItemAdjustmentsTotal"));
                
                store.dispatch(getCartConnected(customerId));
                store.dispatch(fetchAdjustments(orderId));
                store.dispatch(changeInputOrder( false, "loadingTotalOrder"));
                
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(changeInputOrder( false, "loadingTotalOrder"));
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();
                    store.dispatch(changeInputOrder( false, "loadingTotalOrder"));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                } 
            })
        }

        if(action.type === FETCH_PRODUCTS_COURS_IDEAL) {
            // console.log(action.products);
            axios.all(action.products.map((item) => (
                axios.get(`${apiJson}/product_${item}.json`)
            )))
            .then(axios.spread((...responses) => {
            //   console.log(responses);
              store.dispatch(saveCommonDataInState("coursIdealFind", responses));
            }))
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                store.dispatch(saveCommonDataInState("loadingCoursIdealFind", false));
            });
        }

        if(action.type === POST_MAILCHIMP_NEWSLETTER) {
            const newsLetter =  store.getState().user.newsletterEmail;
            axios.post(`${apiBaseUrl}shop/artesane-users/1/abonnement_newsletter`,  {
                "customer_id": Number(action.value),
                "abonnement": newsLetter ?  1 : 0
            }, { headers :  {
                'Content-Type': 'application/ld+json',
                'accept' : 'application/json, text/plain, */*',
                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
            }})
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                  }
    
            });
        }

        if(action.type === FETCH_COURS_IDEAL) {
            axios.get(`${apiJson}/perfect-lesson-form.json`)
            .then((response) => {
                // console.log(response);
                store.dispatch(saveCommonDataInState("loadingCoursIdeal", false));
                store.dispatch(saveCommonDataInState("coursIdeal", response.data.tree));
                store.dispatch(saveCommonDataInState("coursIdealActual", response.data.tree));
                store.dispatch(saveCommonDataInState("coursIdealPrevious", [ response.data.tree] ));
              

            })
            .catch((error) => {
                console.log(error);
                store.dispatch(saveCommonDataInState("loadingCoursIdeal", false));
            });
        }


        if(action.type === FETCH_MAINTENANCE_MODE) {
            axios.get(`${apiBaseUrl}mode-maintenances/1`)
            .then((response) => {
                // console.log(response);
                store.dispatch(saveCommonDataInState("modeMaintenance", response.data.enabled));
            })
            .catch((error) => {
                console.log(error)
            });
        }
        if(action.type === GET_UNIVERS_SEARCH_BAR) {
            axios.get(`${apiBaseUrl}api/v2/elasticsearches/search?recherche=${action.words}&domaine=${action.univers}&page=1&itemsPerPage=300`)
            .then((response) => {
                // console.log(response);

                store.dispatch(saveCommonDataInState("searchUnivers", response.data["hydra:member"]));
                store.dispatch(saveCommonDataInState("loadingUniversSearchbar", false));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(saveCommonDataInState("loadingUniversSearchbar", false));
            })
        }
        if(action.type === GET_GENERAL_SEARCH_BAR) {
            axios.get(`${apiBaseUrl}api/v2/elasticsearches/search?recherche=${action.words}&page=1&itemsPerPage=300`)
            .then((response) => {
                // console.log(response);
                store.dispatch(saveCommonDataInState("searchGeneral", response.data["hydra:member"]));
                store.dispatch(saveCommonDataInState("loadingGeneralSearchbar", false));
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(saveCommonDataInState("loadingGeneralSearchbar", false));
            })
        }

        if(action.type === MODIFY_RESET_PASSWORD) {

            const errorPassword = store.getState().auth.forgetErrorPatternPassword;
            const errorConfirmPassword = store.getState().auth.forgetErrorConfirmPassword;

            // console.log(errorConfirmPassword + " " + errorPassword);

            if(!errorConfirmPassword && !errorPassword) {

                 axios.patch(`${apiBaseUrl}shop/reset-password-requests/${action.value}`, {
                "newPassword" : action.newPassword,
                "confirmNewPassword" : action.confirmPassword
                },{
                    headers :  {
                        'Content-Type': 'application/merge-patch+json',
                        'accept' : 'application/json'
                    }
                })
                .then((response) => {
                    // console.log(response);
                    store.dispatch(changeInputAuthValue(false, "loadingChangePassword"));
                    store.dispatch(changeInputAuthValue("", "forgetNewPassword"));
                    store.dispatch(changeInputAuthValue("", "forgetConfirmNewPassword"));
                    store.dispatch(changeInputAuthValue(true, "popUpPasswordConfirm"));
                    store.dispatch(changeInputAuthValue(true, "redirectAfterChangePassword"));
            
                })
                .catch((error) => {

                    store.dispatch(togglePopupError(true));
                    store.dispatch(changeInputAuthValue(false, "loadingChangePassword"));
                    console.log(error);
                })
            } else {
                store.dispatch(changeInputAuthValue(false, "loadingChangePassword"));
            }
           
        }

        if(action.type === RESET_PASSWORD) {
            axios.post(`${apiBaseUrl}shop/reset-password-requests`, 
                {
                    "email": action.value
                  }
            ,{
                headers :  {
                    'Content-Type': 'application/ld+json',
                    'accept' : 'application/json, text/plain, */*'
                }
            })
            .then((response) => {
                // console.log(response);

                axios.post(`${apiBaseUrl}shop/artesane-users/1/mail-demande-reinitialisation-mot-de-passe`, {
                    "username_canonical": action.value,
                })
                .then((response) => {
                    // console.log(response);
                    store.dispatch(changeInputAuthValue("", "emailForgottenPassword"));
                    store.dispatch(changeInputAuthValue(true, "popUpPasswordConfirm"));
                    store.dispatch(changeInputAuthValue(false, "loadingChangePassword"));
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch(togglePopupError(true));
                    store.dispatch(changeInputAuthValue(false, "loadingChangePassword"));
                })
               
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(togglePopupError(true));
                store.dispatch(changeInputAuthValue(false, "loadingChangePassword"));
            });
        }


        if(action.type === FETCH_METHODES_ENVOIE) {
            axios.get(`${apiBaseUrl}shop/artesane-orders/${action.value}/methodes-envoi`)
            .then((response) => {
                // console.log(response);

                if(response.data["hydra:member"].length === 0 ) {
                    store.dispatch(getCartConnected(store.getState().user.customerId));
                    store.dispatch(changeInputOrder(true, "addressValidate"));
                    store.dispatch(changeInputOrder(false, "chooseDelivery"));
                    store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                }
                else {
                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].id, "shipmentSelected"))
                     store.dispatch(changeInputOrder(response.data["hydra:member"], "shipments"));
                     store.dispatch(changeInputOrder(true, "chooseDelivery"));
                     store.dispatch(changeInputOrder(false, "loadingValidateAddress"));

                }
          
            })
            .catch((error) => {
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();  
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                }  
            })  
        }


        if(action.type === POST_MAIL_CONFIRMATION) {
            // console.log(action.value);
            axios.post(`${apiBaseUrl}shop/artesane-users/1/mail-confirmation-inscription`, {
                "customer_id" : Number(action.value)
            })
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
        }

        if(action.type === PUT_WISHLIST_ITEM) {
            axios.put(`${apiBaseUrl}shop/artesane-users/${action.userId}/wishlist_item`, {
                "user_id": action.userId,
                "product_id": action.productId,
                "ajout": action.ajoute
            }, { headers :  {
                'Content-Type': 'application/ld+json',
                'accept' : 'application/json, text/plain, */*',
                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
            }})
            .then((response) => {
                store.dispatch(wishlistItem([]));
                axios.get(`${apiBaseUrl}artesane-view-wishlists?user_id=${action.userId}&itemsPerPage=600`, { headers : {
                    'Content-Type': 'application/json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }})
                .then((response) => {
                    // console.log(response.data['hydra:member'][0].items);
                    store.dispatch(loadingAddCart(""));
                    store.dispatch(saveWishlist(response.data['hydra:member'][0].items));
                })
                .catch((error) => {
                    console.log(error);
                })
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();
                    store.dispatch(wishlistItem([]));
                    store.dispatch(loadingAddCart(""));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                } 
                
            })
        }                

        if(action.type === FETCH_ADJUSTMENTS) {
            store.dispatch(changeInputOrder( true, "loadingAdjustments"));
            axios.get(`${apiBaseUrl}shop/artesane-orders/${action.value}/details`,  { headers : {
                'Content-Type': 'application/json',
                'accept' : 'application/json, text/plain, */*',
                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
            }})
            .then((response) => {
                // console.log(response);
                const orderAdjustments = response.data["hydra:member"][0].adjustments.filter( element => element.is_neutral === 0 );
                if(response.data["hydra:member"][0].infosCoupon.length > 0 ) {
                    store.dispatch(changeInputOrder((response.data["hydra:member"][0].infosCoupon[0].sylius_promotion_coupon_code), "couponCode"));     
                }
                if(response.data["hydra:member"][0].infosCoupon.length < 1 ) {
                    store.dispatch(changeInputOrder("", "couponCode"));     
                }
                // if(response.data["hydra:member"][0].total_reduction !== 0 ) {
                    store.dispatch(changeInputOrder((response.data["hydra:member"][0].total_reduction), "orderItemAdjustmentsTotal"));    
                // }
                store.dispatch(changeInputOrder(( orderAdjustments ? orderAdjustments : [] ), "orderAdjustments"));
                store.dispatch(changeInputOrder((response.data["hydra:member"][0].items), "orderItemAdjustments"));

                store.dispatch(changeInputOrder( false, "loadingAdjustments"));
                // store.dispatch(changeInputOrder(response.data["hydra:member"][0].order_item, "orderItemAdjustments"));
                // store.dispatch(changeInputOrder(response.data["hydra:member"][0].order_item_unit, "orderUnitItemAdjustments"));

                // for(let i = 0 ; i < response.data["hydra:member"][0].length ; i ++ ) {
                //     if(response.data["hydra:member"][0].order[i].type === "order_promotion" && response.data["hydra:member"][0].order[i].is_neutral === 0 ) {
                //         console.log(response.data["hydra:member"][0].order[i].amount)
                //         store.dispatch(changeInputOrder(response.data["hydra:member"][0].order[i], "adjustmentsPromo"));   
                //         store.dispatch(changeInputOrder(response.data["hydra:member"][0].order[i].sylius_promotion_code, "couponCode"));   
                //     } else if (response.data["hydra:member"][0].order[i].type === "shipping" && response.data["hydra:member"][0].order[i].is_neutral === 0) {
                //         store.dispatch(changeInputOrder(response.data["hydra:member"][0].order[i], "adjustmentsShipping")); 
                    // } 
                    // else {
                    //     console.log("je passe là " + i)
                    //     store.dispatch(changeInputOrder("", "adjustmentsPromo"));   
                    //     store.dispatch(changeInputOrder("", "couponCode")); 
                    //     store.dispatch(changeInputOrder("", "adjustmentsShipping"));   
                    // }
                // }
              
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(changeInputOrder( false, "loadingAdjustments"));
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();
                    store.dispatch(wishlistItem([]));
                    store.dispatch(loadingAddCart(""));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                } 
            });
        }

        if(action.type === PATCH_USER_TIME_IN_VIDEO) {
            axios.patch(`${apiBaseUrl}video-time-saveds/${action.id}`, {
                "course": `/api/v2/courses/${action.courseId}`,
                "user": `/api/v2/shop-users/${action.userId}`,
                "timeInVideo": action.time
            }, {
                headers :  {
                    'Content-Type': 'application/merge-patch+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                } 
            })
            
        }

        if(action.type === POST_COUPON_CODE) {
            const customerId = store.getState().user.customerId;
            const orderId = store.getState().cart.cartData.id;
            axios.post(`${apiBaseUrl}shop/orders/${orderId}/coupon`, {
                "order_id": orderId,
                "code_coupon": action.value,
            }, {
                headers : {
                    'Content-Type': 'application/ld+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }
            })
            .then((response) => {
                console.log(response);
                if(response.data.montantEconomise === null) {
                     store.dispatch(changeInputOrder(response.data.message_retour, "messageRetourCoupon"));
                } else {
                    store.dispatch(changeInputOrder("", "messageRetourCoupon"));
                }
               
                store.dispatch(getCartConnected(customerId));
                store.dispatch(fetchAdjustments(orderId));
                store.dispatch(changeInputOrder( false, "loadingTotalOrder"));
                
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(changeInputOrder( false, "loadingTotalOrder"));
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();
                    store.dispatch(changeInputOrder( false, "loadingTotalOrder"));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                } 
            })
        }

        if(action.type === FETCH_ALL_STOCK_VARIANTS) {
            axios.get(`${apiBaseUrl}artesane-view-product-variants?tracked=true&page=1&itemsPerPage=300`)
            .then((response) => {
                // console.log(response);
                store.dispatch(saveProductVariants(response.data["hydra:member"]));
            })
            .catch((error) => {
                console.log(error);
            });
        }

        if(action.type === PATCH_SHIPPING_METHOD) {
            const orderId = store.getState().cart.cartData.id;
            const customerId = store.getState().user.customerId;
            axios.post(`${apiBaseUrl}shop/orders/${orderId}/shipment` , {
                "order_id": orderId,
                "shipment_method_id" : action.shippingId
                }, {
                    headers : {
                        'Content-Type': 'application/ld+json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }}).then((response) => {
                    // console.log(response);
                    store.dispatch(getCartConnected(customerId));
                    store.dispatch(fetchAdjustments(orderId));
                    store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                    store.dispatch(changeInputOrder(true, "addressValidate"));
                    
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        sessionStorage.clear();
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                      }
                });

        }
        if(action.type === FETCH_PAYPLUG_REUSSITE) {
            axios.get(`${apiBaseUrl}shop/artesane-orders/${action.value}/confirmed`,
             {
                headers :
                {'Content-Type': 'application/ld+json',
                'accept' : 'application/json, text/plain, */*',
                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`}
            })
            .then((response) => {
                // console.log(response);
                store.dispatch(changeInputOrder([], "cartData"));
                store.dispatch(changeInputOrder(0, "cartDataNumber"));
            })
            .catch((error) => {
                console.log(error);
            })
        }

        if(action.type === FETCH_PAYMENT_URL) {
            axios.get(`${apiBaseUrl}shop/artesane-orders/${action.value}/payment`, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }
            })
            .then((response) => {
                // console.log(response)
                if(response.data["hydra:member"][0] === "OK") {
                    store.dispatch(changeInputOrder(response.data["hydra:member"][1], "payplugUrl"));
                    store.dispatch(changeInputOrder(true, "loadingValidateAddress"));
                } else if (response.data["hydra:member"][0] === "ERREUR" && response.data["hydra:member"][1] === "5" ) {
                    store.dispatch(changeInputOrder(`/paiement/${action.value}/reussite`, "payplugUrl"));
                    store.dispatch(changeInputOrder(true, "loadingValidateAddress"));
                }
                
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    sessionStorage.clear();  
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                  }
            })
        }
        if(action.type === PUT_ADD_ADDRESS_ORDER) {
            const isSameAddress = store.getState().cart.sameAddressAsShippingAddress;
            const customerId = store.getState().user.customerId;

            axios.post(`${apiBaseUrl}shop/orders/${action.value}/addresses` , {
                "order_id" : action.value,
                "customer_id" : customerId,
                "billing_address_first_name": isSameAddress ?  store.getState().cart.firstNameShipping : store.getState().cart.firstNameBilling,
                "billing_address_last_name":  isSameAddress ?  store.getState().cart.lastNameShipping : store.getState().cart.lastNameBilling,
                "billing_address_phone_number": isSameAddress ?   store.getState().cart.telephoneShipping : store.getState().cart.telephoneBilling,
                "billing_address_street": isSameAddress ?  store.getState().cart.streetShipping : store.getState().cart.streetBilling,
                "billing_address_complement": isSameAddress ? store.getState().cart.complementShipping :  store.getState().cart.complementBilling,
                "billing_address_company": isSameAddress ?  store.getState().cart.societyShipping :  store.getState().cart.societyBilling,
                "billing_address_city":  isSameAddress ? store.getState().cart.cityShipping : store.getState().cart.cityBilling,
                "billing_address_postcode": isSameAddress ?   store.getState().cart.postCodeShipping : store.getState().cart.postCodeBilling,
                "billing_address_country_code": isSameAddress ?  store.getState().cart.countryShipping : store.getState().cart.countryBilling,
                "billing_address_province_code": "",
                "billing_address_province_name": "",
                
                "shipping_address_first_name": store.getState().cart.firstNameShipping,
                "shipping_address_last_name":  store.getState().cart.lastNameShipping,
                "shipping_address_phone_number":  store.getState().cart.telephoneShipping,
                "shipping_address_street": store.getState().cart.streetShipping,
                "shipping_address_complement": store.getState().cart.complementShipping,
                "shipping_address_company": store.getState().cart.societyShipping,
                "shipping_address_city":  store.getState().cart.cityShipping,
                "shipping_address_postcode": store.getState().cart.postCodeShipping,
                "shipping_address_country_code":  store.getState().cart.countryShipping,
                "shipping_address_province_code": "",
                "shipping_address_province_name": ""
            }, {
                headers : {
                    'Content-Type': 'application/ld+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
            }})
            .then((response) => {
                // console.log(response);

               
                axios.get(`${apiBaseUrl}shop/artesane-orders/${action.value}/methodes-envoi`)
                .then((response) => {
                    // console.log(response);

                    if(response.data["hydra:member"].length === 0 ) {
                        store.dispatch(getCartConnected(store.getState().user.customerId));
                        store.dispatch(changeInputOrder(true, "addressValidate"));
                        store.dispatch(changeInputOrder(false, "chooseDelivery"));
                        store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                    }
                    else {
                        store.dispatch(changeInputOrder(response.data["hydra:member"][0].id, "shipmentSelected"))
                         store.dispatch(changeInputOrder(response.data["hydra:member"], "shipments"));
                         store.dispatch(changeInputOrder(true, "chooseDelivery"));
                         store.dispatch(changeInputOrder(false, "loadingValidateAddress"));

                    }
              
                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        sessionStorage.clear();
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                        store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                    }  
                })  
                            
               
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                  sessionStorage.clear();
                  store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                  store.dispatch(changeInputOrder(false, "loadingValidateAddress"));
                }
                // console.log(error);
              
            })
        }

        if(action.type === FETCH_PRODUCT_CART_ASSOCIATION) {
            // console.log(action.value);
            axios.get(`${apiBaseUrl}artesane-view-product-associations?artesane_product_association_product_id=${action.value}&artesane_product_association_type_translation_name=Association%20pour%20le%20panier&ArtesaneViewProduct_enabled=true&page=1&itemsPerPage=300`)
            .then((response) => {
                // console.log(response);

                if(response.data["hydra:member"].length > 1 ) {
                     store.dispatch(saveCartAssociation(response.data["hydra:member"]));
                }
               
            })
            .catch((error) => {
                console.log(error);
            });
        }              
        if(action.type === FETCH_TOP_SLIDER_ITEMS) {
            axios.get(`${apiBaseUrl}artesane-view-sliders?code=${action.value}&page=1&itemsPerPage=300`)
            .then((response) => {
                // console.log(response);
                store.dispatch(saveTopSliderItems(response.data["hydra:member"]));
            })
            .catch((error) => {
                console.log(error);
            }); 

        }

        if (action.type === ADD_ORDER_ITEM_CONNECTED) {
            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&page=1&itemsPerPage=300`)
            .then((response) => {
                // console.log(response);
                const userCart = response.data["hydra:member"].find(element => element.token_value !== null);
                // console.log(userCart);
                if(userCart) {

                    // axios.post(`${apiBaseUrl}shop/orders/${userCart.token_value}/items`,{"productVariant" : "/api/v2/shop/product-variants/" + action.code, "quantity" : action.quantity},{ headers: {
                    //     'Content-Type': 'application/ld+json',
                    //     'accept' : 'application/json, text/plain, */*',
                    //     'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    // }})
                    axios.put(`${apiBaseUrl}shop/orders/${userCart.id}/item`,
                    {
                        "order_id" : userCart.id,
                        "product_id" : action.productId,
                        "variant_id" : action.variantId,
                         "quantite" : action.quantity
                    },
                    { headers: {
                            'Content-Type': 'application/ld+json',
                            'accept' : 'application/json, text/plain, */*',
                            'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }})
                    .then((response) => {
                        // console.log(response);
                        
                        axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${userCart.token_value}&page=1&itemsPerPage=300`)
                        .then((response) => {
                            store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                            store.dispatch(toggleVariantsChoice(0));
                            store.dispatch(loadingAddCart(""));
                            
                        store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                        

                        if(response.data["hydra:member"][0].items.length === 0) {
                            store.dispatch(countCart(0));
                        }
    
                        if(response.data["hydra:member"][0].items.length === 1) {
                            store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                        } else if (response.data["hydra:member"][0].items.length > 1) {
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                            const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                            store.dispatch(countCart(totalItemsCart));
                           
                        }
                        
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch(loadingAddCart(""));
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            sessionStorage.clear();  
                            
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                          }
                    });
                }else {
                    axios.post(`${apiBaseUrl}shop/orders`,  {"localeCode": "fr_FR"}, { 
                        headers : {
                            'Content-Type': 'application/json',
                            'accept' : 'application/json, text/plain, */*',
                            'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        const newToken  = response.data.tokenValue;
                        // console.log(newToken);

                        axios.put(`${apiBaseUrl}shop/orders/${response.data.id}/item`,
                        {
                            "order_id" : response.data.id,
                            "product_id" : action.productId,
                            "variant_id" : action.variantId,
                             "quantite" : action.quantity
                        },
                        { headers: {
                                'Content-Type': 'application/ld+json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                        }})
                        .then((response) => {
                            // console.log(response);
                            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${newToken}&page=1&itemsPerPage=300`)
                            .then((response) => {
                                // console.log(response);
                                store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));

                            store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                            store.dispatch(loadingAddCart(""));

                            if(response.data["hydra:member"][0].items.length === 0) {
                                store.dispatch(countCart(0));
                            }
        
                            if(response.data["hydra:member"][0].items.length === 1) {
                                store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                                store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                            } else if (response.data["hydra:member"][0].items.length > 1) {
                                store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                                const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                                store.dispatch(countCart(totalItemsCart));
                            
                            }
                            
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch(loadingAddCart(""));
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            sessionStorage.clear();
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                          }
                        // console.log(response);
                    });
                    

                }
            })
            .catch((error) => {
                console.log(error);
            });
            
        }
        if(action.type === GET_CART_CONNECTED) {
            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&page=1&itemsPerPage=300`)
            .then((response) => {

                const userCart = response.data["hydra:member"].find(element => element.token_value !== null);
                // console.log(userCart.token_value);
                if(userCart) {
                    axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${userCart.token_value}&page=1&itemsPerPage=300`)
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                        if(response.data["hydra:member"][0].shipping_address_id !== null) {
                            store.dispatch(changeInputOrder(0, "addressSelectCarnet"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_first_name, "firstNameShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_last_name, "lastNameShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_company, "societyShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_city, "cityShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_complement, "complementShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_street, "streetShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_postcode, "postCodeShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_country_code, "countryShipping"));
                            store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_phone_number, "telephoneShipping"));
                        }

                        store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                        store.dispatch(changeInputOrder(false, "loadingCart"));

                        if(response.data["hydra:member"][0].items.length === 0) {
                            store.dispatch(countCart(0));
                        }
    
                        if(response.data["hydra:member"][0].items.length === 1) {
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                            store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                        } else if (response.data["hydra:member"][0].items.length > 1) {
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                            const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                            store.dispatch(countCart(totalItemsCart));
                           
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
                } 
            })
            .catch((error) => {
                console.log(error);
            });
        }

        if(action.type === VERIFY_CART ) {
            // console.log(action.customer);
            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&page=1&itemsPerPage=300`)
            .then((response) => {
                
                const userCart = response.data["hydra:member"].find(element => element.token_value !== null);
                // console.log(response);
                // console.log(userCart);


               // S'il ya un token en local storage
                if(action.cartToken !== null) {

                    axios.get(`${apiBaseUrl}artesane-view-carts?token_value=${action.cartToken}&page=1&itemsPerPage=300`)
                    .then((response) => {

                        // console.log(response);
                        const orders = response.data["hydra:member"][0];

                        //Si le token panier obsolète
                        if(response.data["hydra:totalItems"] === 0) {

                            
                            localStorage.removeItem("cart");
                           
                            // console.log(" token panier plus valide, à supprimer direct");
                            //manque delete order
                            
                        // s'il trouve bien un panier
                        } else if( response.data["hydra:totalItems"] > 0 ) {
                            // console.log("il trouve une commande panier");
                            //s'il y a des items dans ce panier
                           if (orders.items.length > 0) {
                            // console.log("il y a des items dans l'order trouvée");
                            //Si l'utilisteur a dejà un panier on push les item dessus
                                if(userCart) {
                                    // console.log(userCart);
                                    axios.all( orders.items.map(({ArtesaneProductVariant_code, ArtesaneProductVariant_product_id, variant_id, quantity}) => (
                                        axios.put(`${apiBaseUrl}shop/orders/${userCart.id}/item`,
                                        {
                                            "order_id" : userCart.id,
                                            "product_id" : ArtesaneProductVariant_product_id,
                                            "variant_id" : variant_id,
                                            "quantite" : quantity
                                        },
                                        { headers: {
                                                'Content-Type': 'application/ld+json',
                                                'accept' : 'application/json, text/plain, */*'
                                        }}))))
                                        .then(axios.spread((responses) => {
                                            // console.log("il faut get panier user et delete ancien panier guest");


                                            localStorage.removeItem("cart");
                                            //get  panier avec items
                                            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${userCart.token_value}&page=1&itemsPerPage=300`)
                                            .then((response) => {
                                                store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                                                store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                                                store.dispatch(changeInputOrder(false, "loadingCart"));
                                                if(userCart.items.length > 0 ) {
                                                    store.dispatch(saveCommonDataInState("orderPopup", true));
                                                }
                                                if(response.data["hydra:member"][0].shipping_address_id !== null) {
                                                    store.dispatch(changeInputOrder(0, "addressSelectCarnet"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_first_name, "firstNameShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_last_name, "lastNameShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_company, "societyShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_city, "cityShipping"));
                                                    store.dispatch(changeInputOrder("", "complement"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_street, "streetShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_postcode, "postCodeShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_country_code, "countryShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_phone_number, "telephoneShipping"));
                                                }

                                                if(response.data["hydra:member"][0].items.length === 0) {
                                                    store.dispatch(countCart(0));
                                                }
                            
                                                if(response.data["hydra:member"][0].items.length === 1) {
                                                    store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                                                    store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                                                } else if (response.data["hydra:member"][0].items.length > 1) {
                                                    store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                                                    const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                                                    store.dispatch(countCart(totalItemsCart));
                                                   
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });

                                            //delete ancien panier 
                                            axios.delete(`${apiBaseUrl}shop/orders/${action.cartToken}`)
                                            .then((response) => {
                                                // console.log(response);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                            // console.log(responses);
                                        }))
                                        .catch((error) => {
                                            console.log(error);
                                            if(error.response.status === 401) {
                                                store.dispatch(userConnexion(false));
                                                sessionStorage.clear();
                                                store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                                              }
                                        })
                                        .finally(() => {
                                            //loading à false
                                        })
                                //Si l'utilisateur n'a pas encore de panier, on lui en crée un en lui pushant les items 
                                }else {
                                    // console.log("lutilisateur n'a pas de panier")
                                    // console.log(action.customerToken);
                                    axios.post(`${apiBaseUrl}shop/orders`,  {"localeCode": "fr_FR"}, { 
                                        headers : {
                                            'Content-Type': 'application/json',
                                            'accept' : 'application/json, text/plain, */*',
                                            'Authorization' : `Bearer ${action.customerToken}`
                                        }
                                    })
                                    .then((response) => {
                                        // console.log(response);
                                        // console.log("un nouveau panier pour l'utilisateur créé");
                                        const newToken = response.data.tokenValue;

                                        axios.all( orders.items.map(({ ArtesaneProductVariant_code, ArtesaneProductVariant_product_id, variant_id, quantity}) => (
                                            axios.put(`${apiBaseUrl}shop/orders/${response.data.id}/item`,
                                            {
                                                "order_id" : response.data.id,
                                                "product_id" : ArtesaneProductVariant_product_id,
                                                "variant_id" : variant_id,
                                                "quantite" : quantity
                                            },
                                            { headers: {
                                                    'Content-Type': 'application/ld+json',
                                                    'accept' : 'application/json, text/plain, */*'
                                            }}))))
                                        .then(axios.spread((responses) => {
                                            // console.log("on a push les items trouvés sur ce nouveau panier, plus qu'a get user cart et deelte panier guest");
                                            
                                            //get nouveau panier
                                            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${newToken}&page=1&itemsPerPage=300`)
                                            .then((response) => {
                                                store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                                                store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                                                store.dispatch(changeInputOrder(false, "loadingCart"));
                                                if(response.data["hydra:member"][0].shipping_address_id !== null) {
                                                    store.dispatch(changeInputOrder(0, "addressSelectCarnet"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_first_name, "firstNameShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_last_name, "lastNameShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_company, "societyShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_city, "cityShipping"));
                                                    store.dispatch(changeInputOrder("", "complement"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_street, "streetShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_postcode, "postCodeShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_country_code, "countryShipping"));
                                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_phone_number, "telephoneShipping"));
                                                }

                                                if(response.data["hydra:member"][0].items.length === 0) {
                                                    store.dispatch(countCart(0));
                                                }
                            
                                                if(response.data["hydra:member"][0].items.length === 1) {
                                                    store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                                                    store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                                                } else if (response.data["hydra:member"][0].items.length > 1) {
                                                    store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                                                    const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                                                    store.dispatch(countCart(totalItemsCart));
                                                   
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });

                                            //delete ancien panier guest
                                            axios.delete(`${apiBaseUrl}shop/orders/${action.cartToken}`)
                                            .then((response) => {
                                                // console.log(response);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                            // console.log(responses);
                                        }))
                                        .catch((error) => {
                                            console.log(error);
                                        })
                                        .finally(() => {
                                            //loading à false
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        if(error.response.status === 401) {
                                            store.dispatch(userConnexion(false));
                                            sessionStorage.clear();
                                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                                            
                                          }
                                    });
                                }
                            
                           
                            //S'il n'y a pas ditem dans l'order trouvée
                            } else {
                                // console.log("pas d'items dans l'order trouvée ");
                                localStorage.removeItem("cart");
                                axios.delete(`${apiBaseUrl}shop/orders/${action.cartToken}`)
                                .then((response) => {
                                    // console.log(response);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                               
                            }

                        }
                    
                    })
                    .catch((error) => {
                        console.log(error);
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                            sessionStorage.clear();  
                          }
                    })
                    .finally(() => {

                    });

                }else {
                    // console.log("pas de token cart dans storage");
                    if(userCart) {
                        // console.log("user cart trouvé donc faire get pour regarder s'il y a item dedans")
                        axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${userCart.token_value}&page=1&itemsPerPage=300`)
                        .then((response) => {
                            store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                            store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                            store.dispatch(changeInputOrder(false, "loadingCart"));
                            if(response.data["hydra:member"][0].shipping_address_id !== null) {
                                store.dispatch(changeInputOrder(0, "addressSelectCarnet"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_first_name, "firstNameShipping"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_last_name, "lastNameShipping"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_company, "societyShipping"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_city, "cityShipping"));
                                store.dispatch(changeInputOrder("", "complement"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_street, "streetShipping"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_postcode, "postCodeShipping"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_country_code, "countryShipping"));
                                store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_phone_number, "telephoneShipping"));
                            }
                            if(response.data["hydra:member"][0].items.length === 0) {
                                store.dispatch(countCart(0));
                            }
        
                            if(response.data["hydra:member"][0].items.length === 1) {
                                store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                                store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                            } else if (response.data["hydra:member"][0].items.length > 1) {
                                store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                                const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                                store.dispatch(countCart(totalItemsCart));
                               
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                       
                    } else  {
                        axios.post(`${apiBaseUrl}shop/orders`,  {"localeCode": "fr_FR"}, { 
                            headers : {
                                'Content-Type': 'application/json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${action.customerToken}`
                            }
                        })
                        .then((response) => {
                            axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${action.customer}&state=cart&token_value=${response.data.tokenValue}&page=1&itemsPerPage=300`)
                            .then((response) => {
                                store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                                store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                                store.dispatch(changeInputOrder(false, "loadingCart"));
                                if(response.data["hydra:member"][0].shipping_address_id !== null) {
                                    store.dispatch(changeInputOrder(0, "addressSelectCarnet"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_first_name, "firstNameShipping"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_last_name, "lastNameShipping"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_company, "societyShipping"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_city, "cityShipping"));
                                    store.dispatch(changeInputOrder("", "complement"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_street, "streetShipping"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_postcode, "postCodeShipping"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_country_code, "countryShipping"));
                                    store.dispatch(changeInputOrder(response.data["hydra:member"][0].shipping_address_phone_number, "telephoneShipping"));
                                }
                                if(response.data["hydra:member"][0].items.length === 0) {
                                    store.dispatch(countCart(0));
                                }
            
                                if(response.data["hydra:member"][0].items.length === 1) {
                                    store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                                    store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                                } else if (response.data["hydra:member"][0].items.length > 1) {
                                    store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                                    const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                                    store.dispatch(countCart(totalItemsCart));
                                    
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        })
                        .catch((error) => {
                            // console.log(error);
                            if(error.response.status === 401) {
                                store.dispatch(userConnexion(false));
                                sessionStorage.clear();
                                store.dispatch(changeInputAuthValue(true, "isDeconnect"));  
                              }
                        });
                        // console.log(" pas de user cart trouvé");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
        if(action.type === CHANGE_QUANTITY) {
            const userConnected = store.getState().auth.userConnected;
            const customerId =  store.getState().user.customerId;
            const orderId = store.getState().cart.cartData.id;


            if(!userConnected) {
                axios.put(`${apiBaseUrl}shop/orders/${orderId}/item`,
                {
                    "order_id" : orderId,
                    "product_id" : action.productId,
                    "variant_id" : action.variantId,
                     "quantite" : action.quantity
                },
                { headers: {
                        'Content-Type': 'application/ld+json',
                        'accept' : 'application/json, text/plain, */*'
                }})
                .then((response) => {
                    // console.log(response);
                    store.dispatch(getCartDisconnected(action.token));
                    store.dispatch(loadingDeleteModifyCart(0));
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
                axios.put(`${apiBaseUrl}shop/orders/${orderId}/item`,
                {
                    "order_id" : orderId,
                    "product_id" : action.productId,
                    "variant_id" : action.variantId,
                     "quantite" : action.quantity
                },
                { headers: {
                        'Content-Type': 'application/ld+json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }})
                .then((response) => {
                    // console.log(response);
                   
                    axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${customerId}&state=cart&token_value=${action.token}&page=1&itemsPerPage=300`)
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                        store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                        store.dispatch(loadingDeleteModifyCart(0));
                        if(response.data["hydra:member"][0].items.length === 0) {
                            store.dispatch(countCart(0));
                        }
    
                        if(response.data["hydra:member"][0].items.length === 1) {
                            store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                        } else if (response.data["hydra:member"][0].items.length > 1) {
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                            const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                            store.dispatch(countCart(totalItemsCart));
                           
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        sessionStorage.clear();
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                      }
                });
            }
            
        }

        if(action.type === DELETE_ORDER_ITEM_CART) {
            const customerId =  store.getState().user.customerId;
            const orderId = store.getState().cart.cartData.id;
            if(action.user) {
                // console.log(action.token + " " + action.id);

                axios.put(`${apiBaseUrl}shop/orders/${orderId}/item`,
                {
                    "order_id" : orderId,
                    "product_id" : action.productId,
                    "variant_id" : action.id,
                     "quantite" : 0
                },
                { headers: {
                        'Content-Type': 'application/ld+json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }})
                .then((response) => {
                    // console.log(response);
                    store.dispatch(loadingDeleteModifyCart(0));
                   
                    axios.get(`${apiBaseUrl}artesane-view-carts?customer_id=${customerId}&state=cart&token_value=${action.token}&page=1&itemsPerPage=300`)
                    .then((response) => {
                        store.dispatch(fetchAdjustments(response.data["hydra:member"][0].id));
                        store.dispatch(saveCartInState(response.data["hydra:member"][0]));

                        if(response.data["hydra:member"][0].items.length === 0) {
                            store.dispatch(countCart(0));
                        }
    
                        if(response.data["hydra:member"][0].items.length === 1) {
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                            store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                            
                        } else if (response.data["hydra:member"][0].items.length > 1) {
                            store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                            const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                            store.dispatch(countCart(totalItemsCart));
                           
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });

                  
                  
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response.status === 401) {
                        store.dispatch(loadingDeleteModifyCart(0));
                        store.dispatch(userConnexion(false));
                        sessionStorage.clear();
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));  
                      }
                });
            } else {
                axios.put(`${apiBaseUrl}shop/orders/${orderId}/item`,
                {
                    "order_id" : orderId,
                    "product_id" : action.productId,
                    "variant_id" : action.id,
                     "quantite" : 0
                },
                { headers: {
                        'Content-Type': 'application/ld+json',
                        'accept' : 'application/json, text/plain, */*'
                }})
                .then((response) => {

                    store.dispatch(loadingDeleteModifyCart(0));
                   
                    store.dispatch(getCartDisconnected(action.token));
                  
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }

        if(action.type === CREATE_CART_CONNECTED) {
            if(localStorage.getItem("cart") !== null) {
                // console.log("je passe là")
            }
        }

        if(action.type === GET_CART_DISCONNECTED) {
            axios.get(`${apiBaseUrl}artesane-view-carts?token_value=${action.value}&page=1&itemsPerPage=300`)
            .then((response) => {

                // console.log(response);

                //Si le token panier obsolète
                store.dispatch(changeInputOrder(false, "loadingCart"));
                if(response.data["hydra:totalItems"] === 0) {
                    
                    localStorage.removeItem("cart");
                    //manque delete order
                    
                  // s'il trouve bien un panier
                } else if( response.data["hydra:totalItems"] > 0 ) {

                    store.dispatch(saveCartInState(response.data["hydra:member"][0]));
                    if(response.data["hydra:member"][0].items.length === 0) {
                        store.dispatch(countCart(0));
                    }

                    if(response.data["hydra:member"][0].items.length === 1) {
                        store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[0].ArtesaneProductVariant_product_id));
                        store.dispatch(countCart(response.data["hydra:member"][0].items[0].quantity));
                    } else if (response.data["hydra:member"][0].items.length > 1) {
                        store.dispatch(fetchProductCartAssociation(response.data["hydra:member"][0].items[ response.data["hydra:member"][0].items.length - 1 ].ArtesaneProductVariant_product_id));
                        const totalItemsCart = Object.keys(response.data["hydra:member"][0].items).map(variants => response.data["hydra:member"][0].items[variants].quantity).reduce((previous, current) => previous + current);
                        store.dispatch(countCart(totalItemsCart));
                       
                    }

                }
               
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
        }



        if(action.type === CREATE_CART_DISCONNECTED) {
            const cartToken = localStorage.getItem("cart");
            // console.log(cartToken);
            if(cartToken === null) {
                // console.log(action.productId);
                // console.log(action.variantId);
                // console.log(action.quantity);


                //Si pas de token en localStorage : création nouveau panier
                axios.post(`${apiBaseUrl}shop/orders`, {"localeCode": "fr_FR"})
                .then((response) => {
                    // console.log(response);
                    localStorage.setItem("cart", response.data.tokenValue);
                    axios.put(`${apiBaseUrl}shop/orders/${response.data.id}/item`,
                    {
                        "order_id" : response.data.id,
                        "product_id" : action.productId,
                        "variant_id" : action.variantId,
                        "quantite" : action.quantity
                    },
                    { headers: {
                            'Content-Type': 'application/ld+json',
                            'accept' : 'application/json, text/plain, */*'
                    }})
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(getCartDisconnected(localStorage.getItem("cart")));

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        // console.log("requête finie");
                        store.dispatch(toggleVariantsChoice(0));
                        store.dispatch(loadingAddCart(""));
                    });

                })
                .catch((error) => {
                    console.log(error);
                    store.dispatch(toggleVariantsChoice(0));
                    store.dispatch(loadingAddCart(""));
                });
            } else {
                // vérification si panier est toujours valide
                axios.get(`${apiBaseUrl}artesane-view-carts?token_value=${cartToken}&page=1&itemsPerPage=300`)
                .then((response) => {
    
    
                    //Si le token panier obsolète
                    if(response.data["hydra:totalItems"] === 0) {
                        
                        localStorage.removeItem("cart");

                        axios.post(`${apiBaseUrl}shop/orders`, {"localeCode": "fr_FR"})
                        .then((response) => {
                            // console.log(response);
                            localStorage.setItem("cart", response.data.tokenValue);
                            axios.put(`${apiBaseUrl}shop/orders/${response.data.id}/item`,
                            {
                                "order_id" : response.data.id,
                                "product_id" : action.productId,
                                "variant_id" : action.variantId,
                                "quantite" : action.quantity
                            },
                            { headers: {
                                    'Content-Type': 'application/ld+json',
                                    'accept' : 'application/json, text/plain, */*'
                            }})
                            .then((response) => {
                                console.log(response);
                                store.dispatch(getCartDisconnected(localStorage.getItem("cart")));
        
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => {
                                // console.log("requête finie");
                                store.dispatch(toggleVariantsChoice(0));
                                store.dispatch(loadingAddCart(""));
                            });
        
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                        
                      // s'il trouve bien un panier on  enregistre les nouvelles entrées
                    } else if( response.data["hydra:totalItems"] > 0 ) {

                        console.log(action.productId  + " " + "productId");
                        console.log(action.variantId + " " + "VariantId");
                        console.log(action.quantity + " " + "quantity");    
                        
                        axios.put(`${apiBaseUrl}shop/orders/${response.data["hydra:member"][0].id}/item`,
                        {
                            "order_id" : response.data["hydra:member"][0].id,
                            "product_id" : action.productId,
                            "variant_id" : action.variantId,
                            "quantite" : action.quantity
                        },
                        { headers: {
                                'Content-Type': 'application/ld+json',
                                'accept' : 'application/json, text/plain, */*'
                        }})
                        .then((response) => {
                            console.log(response);
                            store.dispatch(getCartDisconnected(cartToken));
    
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            // console.log("requête finie");
                            store.dispatch(toggleVariantsChoice(0));
                            store.dispatch(loadingAddCart(""));
                        });
                    }
                    
                    
                  
                   
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
    
                });
            }
            
        }

        if(action.type === FETCH_COURSE_ITEM) {

            // console.log(action.value);
            // console.log(action.id);

            axios.get(`${apiBaseUrl}artesane-view-mediatheque-item-cours?artesane_sellable_item_code=${action.value}&sellable_collection_item_sellablecollection_id=${action.id}&page=1&itemsPerPage=30`, { withCredentials: true })
            .then((response) => {
       
                const courseItem = response.data["hydra:member"];
                axios.get(`${apiBaseUrl}artesane-view-mediatheque-item-cours-durees?&course=${response.data["hydra:member"][0].artesane_course_id}&user=${store.getState().user.userId}&page=1&itemsPerPage=300`)
                .then((response) => {
                    // console.log(response);
                    // console.log(response);
                   store.dispatch(saveTimeVideo(response.data["hydra:member"]));
                    store.dispatch(saveCourseItem(courseItem));
                  
                })
                .finally(() => {
                    store.dispatch(loadingCourseItem(false));
                })

                .catch((error) => {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);
            });
        }

        if(action.type === FETCH_SINGLE_COLLECTION) {
            axios.get(`${apiBaseUrl}artesane-view-mediatheques?artesane_association_user_id=${store.getState().user.userId}&artesane_sellable_collection_code=${action.value}&page=1&itemsPerPage=1`)
            .then((response) => {
                const coursItem = response.data["hydra:member"];
                axios.get(`${apiBaseUrl}artesane-view-mediatheque-item-cours-durees?&course=${response.data["hydra:member"][0].artesane_course_id}&user=${store.getState().user.userId}&page=1&itemsPerPage=300`)
                .then((response) => {
                    // console.log(response);
                   store.dispatch(saveTimeVideo(response.data["hydra:member"]));
                    store.dispatch(saveSingleCollection(coursItem));
                  
                })
                .finally(() => {
                    store.dispatch(loadingCollection(false));
                })

                .catch((error) => {
                    console.log(error);
                });
                
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
               
            })
        }

        if(action.type === FETCH_USER_MEDIA) {
            axios.all([
                // ${store.getState().user.userId}
                // armelle 38262
                        axios.get(`${apiBaseUrl}artesane-view-mediatheques?artesane_association_user_id=${store.getState().user.userId}&sylius_product_main_taxon_id=101&page=1&itemsPerPage=30000`),
                        axios.get(`${apiBaseUrl}artesane-view-mediatheques?artesane_association_user_id=${store.getState().user.userId}&sylius_product_main_taxon_id=102&page=1&itemsPerPage=30000`),
                        axios.get(`${apiBaseUrl}artesane-view-mediatheques?artesane_association_user_id=${store.getState().user.userId}&sylius_product_main_taxon_id=103&page=1&itemsPerPage=30000`),
                    ])
            .then(axios.spread((firstResponse, secondResponse, thirdResponse) => {
                // console.log(firstResponse);
                // console.log(secondResponse);
                // console.log(thirdResponse);
                store.dispatch(saveUserMedia("mediaArtsDuFil", firstResponse.data["hydra:member"]));
                store.dispatch(saveUserMedia("mediaArtsPlastiques", secondResponse.data["hydra:member"]));
                store.dispatch(saveUserMedia("mediaArtsCulinaires", thirdResponse.data["hydra:member"]));
            }))
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                store.dispatch(loadingMedia(false));
            });
        }
        if(action.type === PUT_MODIFY_PASSWORD) {
            if(!store.getState().user.errorPatternPassword && !store.getState().user.errorConfirmPassword) {
                axios.put(`${apiBaseUrl}shop/customers/${action.value}/password`, 
                {
                    "newPassword": store.getState().user.newPassword,
                    "confirmNewPassword": store.getState().user.confirmPassword,
                    "currentPassword":store.getState().user.currentPassword
                },
                {  headers: {
                    'Content-Type': 'application/ld+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }
                })
                .then((response) => {
                    // console.log(response);
                    store.dispatch(loadingModifyPassword(false));
                    store.dispatch(changeInputValue(true, "changePasswordConfirm"));
                    store.dispatch(changeInputValue("", "currentPassword"));
                    store.dispatch(changeInputValue("", "newPassword"));
                    store.dispatch(changeInputValue("", "confirmPassword"));


                })
                .catch((error) => {
                    store.dispatch(loadingModifyPassword(false));
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                        sessionStorage.clear();  
                      }
                    if(error.response.data["hydra:description"].includes("mot de passe")) {
                        store.dispatch(changeInputValue(true, "badCurrentPassword"));
                    } else {
                        store.dispatch(changeInputValue(true, "errorModifyPassword"));
                    }
                 
                })
                    


            } else {
                store.dispatch(loadingModifyPassword(false));
            }
        }

        if(action.type === POST_REGISTRATION) {
            console.log(store.getState().auth.newsletterEmailRegistration);
            if(!store.getState().auth.errorPatternPasswordRegistration && !store.getState().auth.errorConfirmPasswordRegistration && !store.getState().auth.errorPatternEmailRegistration && store.getState().auth.capchaRegistrationChecked) {
                axios.all([ 
                            axios.get(`${apiBaseUrl}artesane-view-users?username_canonical=${store.getState().auth.emailRegistration}`),
                            axios.get(`${apiBaseUrl}artesane-customers?username=${store.getState().auth.pseudoRegistration}`)
                        ])
                .then(axios.spread((firstResponse, secondResponse) => {
                    // console.log(secondResponse.data["hydra:totalItems"]);

                    if (firstResponse.data["hydra:totalItems"] > 0 && secondResponse.data["hydra:totalItems"] === 0  ) {
                        store.dispatch(togglePopupError(true, "Cet email existe déjà. Merci de choisir un nouvel email"));
                        store.dispatch(loadingRegistration(false));

                    }else if (secondResponse.data["hydra:totalItems"] > 0 && firstResponse.data["hydra:totalItems"] === 0) {
                        store.dispatch(togglePopupError(true, "Ce pseudo existe déjà. Merci de choisir un nouveau pseudo"));
                        store.dispatch(loadingRegistration(false));
                    } else if ( firstResponse.data["hydra:totalItems"] > 0 && secondResponse.data["hydra:totalItems"] > 0 ) {
                        store.dispatch(togglePopupError(true, "Ce pseudo et cet email existent déjà. Merci de choisir un nouveau pseudo, ainsi qu'un nouvel email"));
                        store.dispatch(loadingRegistration(false));
                    } else {

                        axios.post(`${apiBaseUrl}shop/customers`, {
                            "firstName" : store.getState().auth.firstNameRegistration,
                            "lastName" : store.getState().auth.lastNameRegistration,
                            "email" : store.getState().auth.emailRegistration,
                            "password" : store.getState().auth.passwordRegistration,
                            "subscribedToNewsletter": store.getState().auth.newsletterEmailRegistration,
                        }, {
                            headers : {
                                'Content-Type': 'application/ld+json',
                                'accept' : '*/*',
                            }
                        })
                        .then((response) => {
                            // console.log(response);
                            // axios.patch(`${apiBaseUrl}/api/v2/artesane-customers/${}`)
                            axios.post(`${apiBaseUrl}shop/authentication-token`, {
                                "email": store.getState().auth.emailRegistration,
                                "password": store.getState().auth.passwordRegistration
                            }, {
                                headers: {
                                            'Content-Type': 'application/json',
                                            'accept' : 'application/json',
                                        }
                            })
                                .then((response) => {
                                    store.dispatch(changeInputAuthValue("", "password"));
                                    store.dispatch(loadingConnexion(false));
                    
                                    console.log(response);
                                
                                        //Veirfication panier

                                        sessionStorage.setItem("token", response.data.token);
                                        sessionStorage.setItem("user", store.getState().auth.emailRegistration);
                                        sessionStorage.setItem("username", store.getState().auth.pseudoRegistration);
                                      
                                        const customerId = response.data.customer;
                                        store.dispatch(postMailConfirmation(customerId.substring(customerId.lastIndexOf("/") + 1, customerId.length)));
                                        store.dispatch(postMailChimpNewsletter(customerId.substring(customerId.lastIndexOf("/") + 1, customerId.length)));

                                        store.dispatch(verifyCart(localStorage.getItem("cart"), customerId.substring(customerId.lastIndexOf("/") + 1, customerId.length), response.data.token));
                                        // console.log(customerId.substring(customerId.lastIndexOf("/") + 1, customerId.length));

                                        axios.patch(`${apiBaseUrl}artesane-customers/${customerId.substring(customerId.lastIndexOf("/") + 1, customerId.length)}`, {
                                            "birthday": store.getState().auth.birthDateRegistration,
                                            "phone_number": store.getState().auth.telephoneRegistration,
                                            "username":  store.getState().auth.pseudoRegistration,
                                            "usernameCanonical":  store.getState().auth.pseudoRegistration
                            
                                        }, {
                                                headers: {
                                                    'Content-Type': 'application/merge-patch+json',
                                                    'accept' : 'application/json, text/plain, */*',
                                                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                                                }
                                            })
                                            .then((response) => {
                                                // console.log(response);
                                                store.dispatch(fetchUserData(store.getState().auth.emailRegistration));
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                if(error.response.status === 401) {
                                                    store.dispatch(userConnexion(false));
                                                    sessionStorage.clear();
                                                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));  
                                                  }
                                            })
                                            .finally(() => {
                                                    store.dispatch(loadingRegistration(false));
                                                    store.dispatch(changeInputValue(store.getState().auth.pseudoRegistration, "pseudo"));
                                                    store.dispatch(changeInputAuthValue("", "firstNameRegistration"));
                                                    store.dispatch(changeInputAuthValue("", "lastNameRegistration"));
                                                    store.dispatch(changeInputAuthValue("", "birthDateRegistration"));
                                                    store.dispatch(changeInputAuthValue("", "birthDateRegistration"));      
                                                    store.dispatch(changeInputAuthValue("", "pseudoRegistration"));
                                                    store.dispatch(changeInputAuthValue("", "telephoneRegistration"));
                                                    store.dispatch(changeInputAuthValue("", "passwordRegistration"));
                                                    store.dispatch(changeInputAuthValue("", "confirmPasswordRegistration"));
                                                    store.dispatch(userConnexion(true));
                                            })
                                    
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        })
                        .catch((error) => {
                            store.dispatch(loadingRegistration(false));
                            store.dispatch(togglePopupError(true, ""));
                            console.log(error);
                        })
                  }
                    
                }))
                .catch((error) => {
                    console.log(error);
                    store.dispatch(togglePopupError(true, ""));
                });

            }else {
                store.dispatch(loadingRegistration(false));
            }
        }

        if(action.type === PATCH_SUBSCRIPTIONS) {
            // console.log(action.attribut);
            if(action.attribut === "newsletterEmail") {

                // console.log("je passe ici");
                axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                "subscribed_to_newsletter" : store.getState().user.newsletterEmail
                }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }
                })
                .then((response) => {
                    // console.log(response);
                    store.dispatch(loadingUserRequest(false));
                    store.dispatch(checkSave(true));
                })
                .catch((error) => {
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        sessionStorage.clear();
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                      }
                    console.log(error);
                    store.dispatch(loadingUserRequest(false));
                    store.dispatch(togglePopupError(true, ""));
                });
            }else if (action.attribut === "newsletterSms") {
                axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                    "subscribed_to_newsletter_sms" : store.getState().user.newsletterSMS
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                            'accept' : 'application/json, text/plain, */*',
                            'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(checkSave(true));
                    })
                    .catch((error) => {
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                            sessionStorage.clear();  
                          }
                        console.log(error);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(togglePopupError(true, ""));
                    });
            }else if (action.attribut === "newsletterPaper") {
                axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                    "subscribed_to_newsletter_paper" : store.getState().user.newsletterPaper
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                            'accept' : 'application/json, text/plain, */*',
                            'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(checkSave(true));
                    })
                    .catch((error) => {
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            sessionStorage.clear();  
                          }
                        console.log(error);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(togglePopupError(true, ""));
                    });
            }else if (action.attribut === "cookies") {
                axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                    "cookie_accepted" : store.getState().user.cookies
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                            'accept' : 'application/json, text/plain, */*',
                            'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(checkSave(true));
                    })
                    .catch((error) => {
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                            sessionStorage.clear();  
                          }
                        console.log(error);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(togglePopupError(true, ""));
                    });
            }
            
        }

        if(action.type === PATCH_MY_PERSONAL_INFOS) {
            axios.get(`${apiBaseUrl}artesane-view-users?username_canonical=${store.getState().user.email}`)
            .then((response) => {
                // console.log(response);
                if(response.data["hydra:member"].length === 0) {
                    axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                        "email": store.getState().user.email,
                        "email_canonical": store.getState().user.email,
                        "first_name": store.getState().user.firstName,
                        "last_name":store.getState().user.lastName,
                        "birthday": store.getState().user.birthDate,
                        "gender": store.getState().user.gender,
                        "phone_number": store.getState().user.telephone,
                       
        
                        }, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                            }
                        })
                        .then((response) => {
                            // console.log(response);
                            store.dispatch(loadingUserRequest(false));
                            store.dispatch(checkSave(true));
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response.status === 401) {
                                store.dispatch(userConnexion(false));
                                store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                                sessionStorage.clear();  
                              }
                            store.dispatch(loadingUserRequest(false));
                            store.dispatch(togglePopupError(true, ""));
                        });
                } else if ((response.data["hydra:member"].length > 0 ) && (response.data["hydra:member"][0].ArtesaneCustomer_id === store.getState().user.customerId)){
                    axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                        "email": store.getState().user.email,
                        "email_canonical": store.getState().user.email,
                        "first_name": store.getState().user.firstName,
                        "last_name":store.getState().user.lastName,
                        "birthday": store.getState().user.birthDate,
                        "gender": store.getState().user.gender,
                        "phone_number": store.getState().user.telephone,
                       
        
                        }, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                            }
                        })
                        .then((response) => {
                            // console.log(response);
                            store.dispatch(loadingUserRequest(false));
                            store.dispatch(checkSave(true));
                        })
                        .catch((error) => {
                            console.log(error);
                            store.dispatch(loadingUserRequest(false));
                            store.dispatch(togglePopupError(true, ""));
                        });
                } else {
                    store.dispatch(loadingUserRequest(false));
                    store.dispatch(togglePopupError(true, "Cet email est déjà utilisé. Merci d'en choisir un autre"));
                }
            })
            .catch((error) => {
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    sessionStorage.clear();  
                  }
            });
        }

        
        if(action.type === PATCH_MY_PROFIL_DATA) {
            axios.get(`${apiBaseUrl}artesane-customers?username=${store.getState().user.pseudo}`)
            .then((response) => {
                // console.log(response.data);
                if(response.data["hydra:member"].length === 0 ) {
                    // console.log(store.getState().user.twitch + "" + store.getState().user.youtube);
                    axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                        "username":  store.getState().user.pseudo,
                        "username_canonical":  store.getState().user.pseudo,
                        "bio":  store.getState().user.description,
                        "instagram":  store.getState().user.instagram,
                        "facebook":  store.getState().user.facebook,
                        "youtube":  store.getState().user.youtube,
                        "twitch":  store.getState().user.twitch,
                        "pinterest":  store.getState().user.pinterest,
                        "website":  store.getState().user.website
                        }, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                            }
                        })
                        .then((response) => {
                            // console.log(response);
                            store.dispatch(loadingUserRequest(false));
                            store.dispatch(checkSave(true));
                        })
                        .catch((error) => {
                            console.log(error);
                            store.dispatch(loadingUserRequest(false));
                            store.dispatch(togglePopupError(true, ""));
                        });

                }else if((response.data["hydra:member"].length > 0 ) && (response.data["hydra:member"][0].id === store.getState().user.customerId)) {
                    axios.patch(`${apiBaseUrl}artesane-customers/${action.value}`, {
                    "username":  store.getState().user.pseudo,
                    "username_canonical":  store.getState().user.pseudo,
                    "bio":  store.getState().user.description,
                    "instagram":  store.getState().user.instagram,
                    "facebook":  store.getState().user.facebook,
                    "pinterest":  store.getState().user.pinterest,
                    "youtube":  store.getState().user.youtube,
                    "twitch":  store.getState().user.twitch,
                    "website":  store.getState().user.website,
                    }, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                            'accept' : 'application/json, text/plain, */*',
                            'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(checkSave(true));
                    })
                    .catch((error) => {
                        console.log(error);
                        store.dispatch(loadingUserRequest(false));
                        store.dispatch(togglePopupError(true, ""));
                    });
                }else {
                    store.dispatch(loadingUserRequest(false));
                    store.dispatch(togglePopupError(true, "Ce pseudo est déjà utilisé. Merci d'en choisir un autre"));
                }
                
            })
            .catch((error) => {
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    sessionStorage.clear();  
                  }
            });
        }

        if(action.type === DELETE_ADDRESS) {
            axios.delete(`${apiBaseUrl}shop/carnet-adresses/${action.value}`, {
                headers : {
                    'Content-Type': 'application/json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }
            })
            .then((response) => {
                axios.get(`${apiBaseUrl}shop/carnet-adresses?page=1&itemsPerPage=600`, { headers : {
                    'Content-Type': 'application/json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }})
                .then((response) => {
                    store.dispatch(changeInputValue(response.data['hydra:member'], "addresses"));
                    store.dispatch(loadingDeleteAddress(false));
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                        sessionStorage.clear();  
                      }
                    // console.log(response);
                })
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    sessionStorage.clear();  
                  }
                store.dispatch(loadingDeleteAddress(false));
                store.dispatch(togglePopupError(true, ""));
            });
        }

        if(action.type === DEFAULT_ADDRESS) {
            axios.patch(`${apiBaseUrl}shop/carnet-adresses/${action.value}/default`, {
                "par_defaut": true
            }, {
                headers : {
                    'Content-Type': 'application/merge-patch+json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }
            })
            .then((response) => {
                // console.log(response);
                 
                axios.get(`${apiBaseUrl}shop/carnet-adresses?page=1&itemsPerPage=600`, { headers : {
                    'Content-Type': 'application/json',
                    'accept' : 'application/json, text/plain, */*',
                    'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                }})
                .then((response) => {
                    store.dispatch(changeInputValue(response.data['hydra:member'], "addresses"));
                    store.dispatch(loadingAddAddress(false));
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                        sessionStorage.clear();  
                      }
                    // console.log(response);
                    store.dispatch(loadingAddAddress(false));
                })
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    sessionStorage.clear();  
                  }
                // console.log(response);
            });
        }

        if(action.type === POST_NEW_ADDRESS) {
            if(!store.getState().user.errorPatternTelephoneAddressAdd) {
                // console.log(store.getState().user.streetAddressAdd);
                // console.log(store.getState().user.complementAddressAdd);
                axios.post(`${apiBaseUrl}shop/carnet-adresses`, {
                    "nom" : "Autre adresse",
                    "first_name": store.getState().user.firstNameAddressAdd,
                    "last_name": store.getState().user.lastNameAddressAdd,
                    "phone_number": store.getState().user.telephoneAddressAdd,
                    "street": store.getState().user.streetAddressAdd,
                    "complement" : store.getState().user.complementAddressAdd,
                    "company": store.getState().user.societyAddressAdd,
                    "city": store.getState().user.cityAddressAdd,
                    "postcode": store.getState().user.postCodeAddressAdd,
                    "country_code": store.getState().user.countryAddressAdd,
                    "province_code": null,
                    "province_name": "",
                    "par_defaut" : store.getState().user.defaultAdressAdd
                   
                    
                   
                    
    
                },{
                    headers : {
                        'Content-Type': 'application/json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }
                })
                .then((response) => {
                    // console.log("hello");
                    // console.log(response);
                    store.dispatch(changeInputValue("", "lastNameAddressAdd"));
                    store.dispatch(changeInputValue("", "firstNameAddressAdd"));
                    store.dispatch(changeInputValue("", "streetAddressAdd"));
                    store.dispatch(changeInputValue("", "complementAddressAdd"));
                    store.dispatch(changeInputValue("", "societyAddressAdd"));
                    store.dispatch(changeInputValue("", "postCodeAddressAdd"));
                    store.dispatch(changeInputValue("", "cityAddressAdd"));
                    store.dispatch(changeInputValue("FR", "countryAddressAdd"));
                    store.dispatch(changeInputValue("", "telephoneAddressAdd"));
                    store.dispatch(changeInputValue(false, "defaultAdressAdd"));
                    
                    axios.get(`${apiBaseUrl}shop/carnet-adresses?page=1&itemsPerPage=600`, { headers : {
                        'Content-Type': 'application/json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }})
                    .then((response) => {
                        store.dispatch(changeInputValue(response.data['hydra:member'], "addresses"));
                        store.dispatch(loadingAddAddress(false));
                    })
                    .catch((error) => {
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                            sessionStorage.clear();  
                          }
                        // console.log(response);
                    })
                   
                })
                .catch((error) => {
                    if(error.response.status === 401) {
                        store.dispatch(userConnexion(false));
                        store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                        sessionStorage.clear();  
                      }
                    store.dispatch(loadingAddAddress(false));
                    store.dispatch(togglePopupError(true, ""));
                    console.log(error);
                });
            } else {
                // console.log("je passe là")
                store.dispatch(loadingAddAddress(false));
            }
            
        }

    if(action.type === FETCH_COUNTRIES) {
        axios.get(`${apiBaseUrl}shop/countries?page=1&itemsPerPage=600`)
            .then((response) => {
                // console.log(response);
                store.dispatch(saveCountries(response.data["hydra:member"]));
            })
            .catch((error) => {
                console.log(error);
            });
    }
        
    

    if(action.type === PUT_MODIFY_ADDRESS) {
        axios.put(`${apiBaseUrl}shop/carnet-adresses/${action.value}`, {
            "firstName": store.getState().user.firstNameAddressModify,
            "lastName": store.getState().user.lastNameAddressModify,
            "phoneNumber": store.getState().user.telephoneAddressModify,
            "company": store.getState().user.societyAddressModify,
            "countryCode": store.getState().user.countryAddressModify,
            "provinceCode": null,
            "provinceName": "",
            "street": store.getState().user.streetAddressModify,
            "city": store.getState().user.cityAddressModify,
            "postcode": store.getState().user.postCodeAddressModify,
        }, {
            headers : { 
                        'Content-Type': 'application/json',
                        'accept' : 'application/json, text/plain, */*',
                        'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                    }
        })
        .then((response) => {
            // console.log(response)
            axios.get(`${apiBaseUrl}shop/carnet-adresses?page=1&itemsPerPage=600`, { headers : {
                'Content-Type': 'application/json',
                'accept' : 'application/json, text/plain, */*',
                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
            }})
            .then((response) => {
               
            
                store.dispatch(changeInputValue(response.data["hydra:member"], "addresses"));
                store.dispatch(loadingModifyAddress(false));
                store.dispatch(toggleModifyAddress(false));
                // store.dispatch(changeInputValue(userData.youtube !== null ? userData.youtube.split('/')[3] : "", "youtube"));
                // store.dispatch(changeInputValue(userData.pinterest !== null ? userData.pinterest.split('/')[3] : "", "pinterest"));
                // console.log(response);
            })
            .catch((error) => {
                // console.log("je suis là erruer")
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    sessionStorage.clear();  
                  }
               
                console.log(error);
            })
          
        })
        .catch((error) => {

            if(error.response.status === 401) {
                store.dispatch(userConnexion(false));
                store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                sessionStorage.clear();  
              }
            store.dispatch(errorModifyAddress(true));
            store.dispatch(loadingModifyAddress(false));
            console.log(error);
        });
    }

    if(action.type === POST_CONNEXION) {
        axios.post(`${apiBaseUrl}shop/authentication-token`, {
            "email": action.email,
            "password": action.password
        }, {
            headers: {
                        'Content-Type': 'application/json',
                        'accept' : 'application/json',
                    }
        })
            .then((response) => {
                store.dispatch(changeInputAuthValue("", "password"));
           
                store.dispatch(loadingConnexion(false));

                // console.log(response);
                // console.log(action.stayConnected);
                if(action.stayConnected) {
                    sessionStorage.setItem("token", response.data.token);
                    sessionStorage.setItem("user", action.email);

                }
                const customerId = response.data.customer.match(/[^\/]+$/)[0];
                // console.log(customerId);
                store.dispatch(verifyCart(localStorage.getItem("cart"), Number(customerId), response.data.token));
                store.dispatch(fetchUserData(action.email, response.data.token));
                store.dispatch(userConnexion(true));

               
                
            })
            .catch((error) => {
                store.dispatch(loadingConnexion(false));
                store.dispatch(errorConnexion(true));
                console.log(error);
            });
    }
    if(action.type === POST_EMAIL_VERIFICATION) {
        axios.get(`${apiBaseUrl}artesane-view-users?username_canonical=${action.value}`)
            .then((response) => {
                // console.log(response.data["hydra:totalItems"] > 0 ? true : false);
                store.dispatch(isEmailExist(response.data["hydra:totalItems"] > 0 ? true : false));
                // store.dispatch(togglePopupVerifiedEmail(false));

                
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if(action.type === FETCH_ORDER_ITEMS) {
        axios.get(`${apiBaseUrl}artesane-view-order-items?order_id=${action.value}&itemsPerPage=600`)
            .then((response) => {
                // console.log(response.data["hydra:member"]);
                store.dispatch(saveOrderItems(response.data["hydra:member"]));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                store.dispatch(loadingOrderItems(false));
            })
    }


    if (action.type === FETCH_USER_DATA) {

        const wishlistItem = store.getState().user.wishlistItemSaved;

        axios.get(`${apiBaseUrl}artesane-view-users?username_canonical=${action.value}`)
            .then((response) => {
                // console.log(response);

                const userData = response.data['hydra:member'][0];
                // console.log(userData);
                // sessionStorage.setItem("username" , userData.ArtesaneCustomer_username);
                store.dispatch(changeInputValue(response.data['hydra:member'][0].id, "userId"));
                store.dispatch(changeInputValue(userData.ArtesaneCustomer_id, "customerId"));
                store.dispatch(changeInputValue((userData.bio === undefined || userData.bio === null ) ? "":  userData.bio, "description"));
                store.dispatch(changeInputValue((userData.birthday !== null ? userData.birthday.slice(0, 10) : "") , "birthDate"));
                store.dispatch(changeInputValue(userData.first_name, "firstName"));
                store.dispatch(changeInputValue(userData.last_name, "lastName"));
                store.dispatch(changeInputValue(userData.gender, "gender"));
                store.dispatch(changeInputValue(userData.ArtesaneCustomer_email, "email"));
                store.dispatch(changeInputValue(userData.phone_number === null ? "" : userData.phone_number, "telephone"));
                store.dispatch(changeInputValue(userData.url_original, "userImageUrl"));
                store.dispatch(changeInputValue(null, "userImageFile"));
                store.dispatch(changeInputValue(userData.ArtesaneCustomer_username, "pseudo"));
                // store.dispatch(changeInputValue(userData.addresses, "addresses"));
                store.dispatch(changeInputValue(userData.subscribed_to_newsletter, "newsletterEmail"));
                store.dispatch(changeInputValue(userData.subscribed_to_newsletter_sms, "newsletterSMS"));
                store.dispatch(changeInputValue(userData.subscribed_to_newsletter_paper, "newsletterPaper"));
                store.dispatch(changeInputValue(userData.cookie_accepted, "cookies"));
                store.dispatch(changeInputValue(userData.default_address, "defaultAddress"));
                store.dispatch(changeInputValue(userData.instagram === null ? "" : userData.instagram, "instagram"));
                store.dispatch(changeInputValue(userData.facebook === null ? "" : userData.facebook, "facebook"));
                store.dispatch(changeInputValue(userData.youtube === null ?  "" : userData.youtube, "youtube"));
                store.dispatch(changeInputValue(userData.twitch === null ?  "" : userData.twitch, "twitch"));
                store.dispatch(changeInputValue(userData.pinterest === null ? "" : userData.pinterest, "pinterest"));

               

               
                axios.all([ axios.get(`${apiBaseUrl}artesane-view-orders?customer_id=${userData.customer_id}&itemsPerPage=600`),
                            axios.get(`${apiBaseUrl}artesane-view-wishlists?user_id=${userData.id}&itemsPerPage=600`, { headers : {
                                'Content-Type': 'application/json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                            }}),
                            axios.get(`${apiBaseUrl}shop/carnet-adresses?page=1&itemsPerPage=600`, { headers : {
                                'Content-Type': 'application/json',
                                'accept' : 'application/json, text/plain, */*',
                                'Authorization' : `Bearer ${sessionStorage.getItem("token")}`
                            }})
                        ])
                    .then(axios.spread((firstResponse, secondResponse, thirdResponse) => {

                        // console.log(firstResponse);
                        // console.log(secondResponse);
                        // console.log(thirdResponse);
                        store.dispatch(saveOrders(firstResponse.data['hydra:member']));
                        const wishlistItems = secondResponse.data['hydra:member'][0] !== undefined ? secondResponse.data['hydra:member'][0].items : [];
                        store.dispatch(saveWishlist(wishlistItems));
                        store.dispatch(changeInputValue(false, "loadingWishlist"));

                        if(wishlistItem.length > 0 ) {
                            store.dispatch(putWishlistItem(userData.id, wishlistItem[0], true));
                        }

                        
                        store.dispatch(changeInputValue(thirdResponse.data["hydra:member"], "addresses"));
                    }))
                    .catch((error) => {

                        console.log(error);
                        if(error.response.status === 401) {
                            store.dispatch(userConnexion(false));
                            store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                            sessionStorage.clear();  
                          }
                        console.log(error);
                    })
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 401) {
                    store.dispatch(userConnexion(false));
                    store.dispatch(changeInputAuthValue(true, "isDeconnect"));
                    sessionStorage.clear();  
                  }
            });
    }

    if (action.type === FETCH_ALL_PROFESSORS) {
        axios.get(`${apiBaseUrl}artesane-view-teacher-resumes?&enabled=true&itemsPerPage=600`)
            .then((response) => {
                // console.log(response.data['hydra:member']);
                
                store.dispatch(saveAllProfessors(response.data['hydra:member']));
            
            })
            .catch((error) => {
                console.log(error);
            });
    }


    if(action.type === FETCH_PROFESSEUR_PUCLIC_DATA ) {
        axios.get(`${apiBaseUrl}artesane-view-teachers?code=${action.value}`)
            .then((response) => {
                // console.log(response.data['hydra:member'][0]);
                store.dispatch(saveProfesseurPublicData(response.data['hydra:member']));
            })
            .catch((error) => {
                console.log(error);
            });
    } 

    if(action.type === FETCH_PROFESSEUR_CATALOGUE_DATA) {
        // console.log(action.value);

        //teacher taxon
        axios.get(`${apiBaseUrl}artesane-view-teacher-resumes?subjects.id_niveau_1=${action.value}&enabled=true&page=1&itemsPerPage=600`)
            .then((response)=> {
                // console.log(response.data['hydra:member']);
                store.dispatch(saveProfesseurCatalogueData(response.data['hydra:member']));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if(action.type === FETCH_PRODUCT_DATA) {
        // console.log('je passe là');
        axios.get(`${apiJson}/product_${action.value}.json`)
            .then((response) => {
                // console.log(response);
                // console.log(action.value);
                store.dispatch(saveProductData(response.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                store.dispatch(isLoadingProduct(false));
            })
    }

    if(action.type === FETCH_MEGA_MENU) {
        // console.log(action.filters);
        // console.log(action.value);
        if (action.value !== undefined) {
            store.dispatch(toggle404(false));
        axios.get(`${apiBaseUrl}artesane-megamenus?domaine_id=${action.value}&niveau=1`)
            .then((response) => {
                const navData = response.data['hydra:member'];
                // console.log(navData);
                const filtreObjet = store.getState().catalogue.filterObject;
                const lessonsData = store.getState().catalogue.catalogueData;
                // console.log(filtreObjet);

                store.dispatch(saveMegaMenu(response.data['hydra:member']));
                // console.log(navData);


                //TABLEAU DE CORRESPONDANCE { SLUG <=> CHAINE }
                const tableauCorrespondance = [];
                for(let index = 0 ; index < navData.length; index ++) {
                    let object = {};
                    object['chaine'] = navData[index].choix;
                    object['slug'] = navData[index].slug;
                    tableauCorrespondance.push(object);
                    for(let indexPremier = 0; indexPremier < navData[index].fils.length; indexPremier ++) {
                        for(let indexDeuxieme = 0; indexDeuxieme < navData[index].fils[indexPremier].fils.length; indexDeuxieme ++ ) {
                            let object = {};
                            object['chaine'] = navData[index].fils[indexPremier].fils[indexDeuxieme].choix;
                            object['slug'] = navData[index].fils[indexPremier].fils[indexDeuxieme].slug;
                            tableauCorrespondance.push(object);
                        }

                    }
                }
                // console.log(tableauCorrespondance);
                const searchObject = tableauCorrespondance.find(object => object.slug === action.filters);
                // console.log(searchObject);

                if(action.filters !== undefined && action.filters !== "tout") {

                    // console.log(action.filters);

                    //  searchObject = tableauCorrespondance.find(object => object.slug === action.filters);
                    // console.log(filtreObjet);
                    if(searchObject !== undefined ) {
                            store.dispatch(removeAllFilters());
                            store.dispatch(toggle404(false));
                            // console.log(filtreObjet);
                            const choixCoche =  artesaneFiltre_changerPlusieursEtats(filtreObjet, searchObject.chaine, lessonsData, "PlusRecent");
                            
                            // console.log(choixCoche);
                                // dispatch(addFiltersCheckbox(id, nom));
                                for(let index = 0 ;  index < choixCoche.length ; index ++) {
                                store.dispatch(addFiltersCheckbox(choixCoche[index].id, choixCoche[index].nom ));
                                // store.dispatch(isLoadingMegaMenu(false));
                
                            }
                    }else{

                        // console.log("404");
                        // store.dispatch(isLoadingMegaMenu(false));
                        store.dispatch(toggle404(true));
                      

                    }
                           
                }

            })
        
        
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                // console.log("finally");
                store.dispatch(isLoadingMegaMenu(false));
            });
        } else {
            store.dispatch(isLoadingMegaMenu(false));
            store.dispatch(toggle404(true));
        }
    }

    if(action.type === FETCH_FAQS) {
        axios.get(`${apiBaseUrl}artesane-view-faqs`)
        .then((response) => {
            const faqsData = response.data['hydra:member'];
            // console.log(faqsData);
            store.dispatch(saveHomeDataInState('faqsData', faqsData));

        })
        .catch((error) => {
            console.log(error)
        });
    }

    if(action.type === FETCH_PROFESSORS) {
       
        axios.get(`${apiBaseUrl}artesane-view-teacher-resumes?subjects.id_niveau_1=${action.id}&order=true&highlight=true`)
            .then((response) => {
                // console.log(action.id);
                const professors = response.data['hydra:member'];
                
                // console.log(response);
                axios.get(`${apiBaseUrl}artesane-view-teacher-resumes?subjects.id_niveau_1=${action.id}&order=false&highlight=true`)
                .then((response) => {
                    
                    const professors2 = response.data['hydra:member'];
                    // console.log(professors2);

                    professors2.map((item) => professors.push(item));
                    store.dispatch(saveCommonDataInState('professors', professors));
                    store.dispatch(saveCommonDataInState("loadingProfessors", false));
                  
                }).catch((error) => {
                    console.log(error);
                })
            }).catch((error) => {
                console.log(error);
            });
    }

    if(action.type === FETCH_LESSONS) {
        // console.log(action.value);
        axios.get(`${apiJson}/produits_domaine_${action.value}.json`)
            .then((response) => {
               
                const lessonsData = response.data['hydra:member'];
                // console.log(response);

                const numberOfItems = lessonsData.length;
                store.dispatch(saveCatalogueLessonsInState(lessonsData, numberOfItems));


                axios.get(`${apiJson}/filtres_domaine_${action.value}.json`)
                    .then((response) => {
                    const filtersByUnivers = response.data.fils;
                    // console.log(action.value);
                    // console.log(filtersByUnivers);
                    const filtreObject = new Artesane_Filtre(response.data.nom, lessonsData.length);
                    for(let critere = 0 ; critere < filtersByUnivers.length ; critere ++ ) {
                        let criterecourant = filtreObject.addCritere(filtersByUnivers[critere].id, filtersByUnivers[critere].nom, filtersByUnivers[critere].rang );
                        // console.log(filtersByUnivers[critere].rang);
        
                        for( let choix = 0 ; choix < filtersByUnivers[critere].fils.length; choix ++) {
                            criterecourant.addChoix(filtersByUnivers[critere].fils[choix].id, filtersByUnivers[critere].fils[choix].nom, filtersByUnivers[critere].fils[choix].affectation )
                        }
                       
                    }

                    artesaneFiltre_calculerEtatDesProduits(filtreObject);
                    artesaneTriChoix(filtreObject.tableauTri, lessonsData, "PlusRecent" );
                    // artesaneFiltre_changerPlusieursEtats(filtreObject, "-000-0000000-00000-0000000-000--00000100000000-0000000-000000000000000-000000000000-00000-0000-000000000000000000000000000000000-000000-0000-0000000-0000-000000000-00000000-000000", lessonsData, "PlusRecent");

        
        
                    store.dispatch(saveFilters(filtersByUnivers, filtreObject));
                    // filtreObject.criteres.sort((a, b) => b.rang - a.rang );

                        // axios.get(`${apiBaseUrl}/api/v2/artesane-mega-menus?domaine_id=${action.value}&niveau=1`)
                        // .then((response) => {
                        //     console.log('coucou');
                        //     const navData = response.data['hydra:member'];
            
                        //     store.dispatch(saveMegaMenu(response.data['hydra:member']));
                        //     // console.log(navData);
            
            
                        //     //TABLEAU DE CORRESPONDANCE { SLUG <=> CHAINE }
                        //     const tableauCorrespondance = [];
                        //     for(let index = 0 ; index < navData.length; index ++) {
                        //         let object = {};
                        //         object['chaine'] = navData[index].choix;
                        //         object['slug'] = navData[index].slug;
                        //         tableauCorrespondance.push(object);
                        //         for(let indexPremier = 0; indexPremier < navData[index].fils.length; indexPremier ++) {
                        //             for(let indexDeuxieme = 0; indexDeuxieme < navData[index].fils[indexPremier].fils.length; indexDeuxieme ++ ) {
                        //                 let object = {};
                        //                 object['chaine'] = navData[index].fils[indexPremier].fils[indexDeuxieme].choix;
                        //                 object['slug'] = navData[index].fils[indexPremier].fils[indexDeuxieme].slug;
                        //                 tableauCorrespondance.push(object);
                        //             }
            
                        //         }
                        //     }
                        //     const searchObject = tableauCorrespondance.find(object => object.slug === action.filters);
                        //     // console.log(searchObject);
            
                        //     if(action.filters !== undefined && action.filters !== "tout") {
            
                        //         console.log(action.filters);
            
                        //         //  searchObject = tableauCorrespondance.find(object => object.slug === action.filters);
                        //         console.log(action.filters);
                        //         if(searchObject !== undefined ) {
                        //                 store.dispatch(removeAllFilters());
                        //                 store.dispatch(toggle404(false));
                        //                 console.log(filtreObjet);
                        //                 const choixCoche =  artesaneFiltre_changerPlusieursEtats(filtreObjet, searchObject.chaine, lessonsData, "PlusRecent");
                        //                 console.log('je rentre dans ta condition');
                        //                     // dispatch(addFiltersCheckbox(id, nom));
                        //                     for(let index = 0 ;  index < choixCoche.length ; index ++) {
                        //                     store.dispatch(addFiltersCheckbox(choixCoche[index].id, choixCoche[index].nom ));
                        //                     // store.dispatch(isLoadingMegaMenu(false));
                            
                        //                 }
                        //         }else{
            
                        //             console.log("404");
                        //             store.dispatch(toggle404(true));
                                
            
                        //         }
                                    
                        //     }
            
                        // })
                        // .catch((error) => {
                        //     console.log(error);
                        // })
                        // .finally(() => {
                        //     console.log("finally");
                            
                        //     // store.dispatch(isLoadingMegaMenu(false));
                        // });

                    })
                    .catch((error) => {
                        console.log(error)
                        store.dispatch(toggleLoading(false));
                    }).finally(() => {
                        
                        store.dispatch(toggleLoading(false));
                       
                    });
                    
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(toggleLoading(false));
            });
            
    }

    if(action.type === FETCH_LESSONS_SLIDER) {
        // console.log(action.value);
        axios.get(`${apiBaseUrl}artesane-view-product-resumes?main_taxon_id=${action.value}&highlight=true&enabled=true&page=1&itemsPerPage=600'`)
        .then((response) => {
            // console.log(response);
            store.dispatch(saveLessonsSlider(response.data['hydra:member']));
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // if(action.type === FETCH_FILTERS) {
    //     axios.get(`http://192.168.100.24:8001/filtres_domaine_${action.value}.json`)
    //         .then((response) => {
    //             console.log(response);
    //            const filtersByUnivers = response.data.fils;
    //            const filtreObject = new Artesane_Filtre(response.data.nom, lessons.length);
    //            for(let critere = 0 ; critere < filtersByUnivers.length ; critere ++ ) {
    //              let criterecourant = filtreObject.addCritere(filtersByUnivers[critere].nom);

    //               for( let choix = 0 ; choix < filtersByUnivers[critere].fils.length; choix ++) {
    //                 criterecourant.addChoix(filtersByUnivers[critere].fils[choix].id, filtersByUnivers[critere].fils[choix].nom, filtersByUnivers[critere].fils[choix].affectation )
    //               }
    //            }


    //            store.dispatch(saveFilters(filtersByUnivers, filtreObject));
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         });
    // }

    next(action);
};

export default apiMiddleware;