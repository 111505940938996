export const FETCH_USER_MEDIA = "FETCH_USER_MEDIA";
export const SAVE_USER_MEDIA = "SAVE_USER_MEDIA";
export const LOADING_MEDIA = "LOADING_MEDIA";
export const TOGGLE_FILTERS_OPEN = "TOGGLE_FILTERS_OPEN";
export const CHANGE_FILTERS = "CHANGE_FILTERS";
export const FETCH_SINGLE_COLLECTION = "FETCH_SINGLE_COLLECTION";
export const SAVE_SINGLE_COLLECTION = "SAVE_SINGLE_COLLECTION"; 
export const LOADING_COLLECTION = "LOADING_COLLECTION";
export const FETCH_COURSE_ITEM = "FETCH_COURSE_ITEM";
export const SAVE_COURSE_ITEM = "SAVE_COURSE_ITEM";
export const LOADING_COURSE_ITEM = "LOADING_COURSE_ITEM";
export const SAVE_CHAPTERS = "SAVE_CHAPTERS"; 
export const TOGGLE_CHAPTER_OPEN = "TOGGLE_CHAPTER_OPEN";
export const SAVE_TIME_VIDEO = " SAVE_TIME_VIDEO";
export const PATCH_USER_TIME_IN_VIDEO = "PATCH_USER_TIME_IN_VIDEO";
export const TOGGLE_CHAT_BUTTON = "TOGGLE_CHAT_BUTTON";





export const toggleChatButton = (newValue) => ({
    type : TOGGLE_CHAT_BUTTON,
    value: newValue
});

export const patchUserTimeInVideo = (courseId, userId, time, id) => ({
    type: PATCH_USER_TIME_IN_VIDEO,
    courseId: courseId,
    userId : userId,
    time: time,
    id: id,
});

export const saveTimeVideo = (newValue) => ({
    type: SAVE_TIME_VIDEO,
    value: newValue,
});

export const toggleChapterOpen = (newValue) => ({
    type: TOGGLE_CHAPTER_OPEN,
    value: newValue
});

export const saveChapters = (newValue) => ({
    type: SAVE_CHAPTERS,
    value: newValue,
});

export const loadingCourseItem = (newValue) => ({
    type: LOADING_COURSE_ITEM,
    value: newValue,
});

export const saveCourseItem = (newValue) => ({
    type: SAVE_COURSE_ITEM,
    value: newValue,
});

export const fetchCourseItem = (newValue, idCours) => ({
    type: FETCH_COURSE_ITEM,
    value: newValue,
    id: idCours,
});

export const saveSingleCollection = (newValue) => ({
    type: SAVE_SINGLE_COLLECTION,
    value: newValue,
});

export const loadingCollection = (newValue) => ({
    type : LOADING_COLLECTION,
    value: newValue,
});

export const fetchSingleCollection = (newValue) => ({
    type: FETCH_SINGLE_COLLECTION,
    value: newValue,
});

export const changeFilters = (filterName, newValue, nb) => ({
    type: CHANGE_FILTERS,
    name: filterName,
    value: newValue,
    filterNb: nb,  
});

export const toggleFiltersOpen = (newValue) => ({
    type: TOGGLE_FILTERS_OPEN,
    value: newValue,
});

export const loadingMedia = (newValue) => ({
    type : LOADING_MEDIA,
    value: newValue,
});

export const saveUserMedia = (stateItem, newValue) => ({
    type: SAVE_USER_MEDIA,
    value: newValue,
    name: stateItem,
});

export const fetchUserMedia = (newValue) => ({
    type: FETCH_USER_MEDIA,
    value: newValue,
});