//Actions
import { TOGGLE_SELECT_PRODUCT_CHOICE,
         SELECT_VARIANT,
         ACCORDION_MENU_ACTIF,
         PRODUCT_SLIDER_CHANGE_INDEX,
         RECOMMANDATIONS_SLIDER_CHANGE_INDEX,
         SAVE_PRODUCT_DATA,
         IS_LOADING_PRODUCT,
         PRODUCT_SLIDER_INDEX_TO_SHOW,
         TOGGLE_THUMBNAIL_PRODUCT_HOVER,
         TOGGLE_SLIDER_ZOOM_PRODUCT,
         SLIDER_ZOOM_INDEX_TO_SHOW,
         SAVE_PRODUCT_DATA_STATE,
        } from "../actions/productsActions";


const initialState = {
    isOpenSelectChoice : false,
    variantSelected : "",
    accordionMenuActif: "Résumé",
    productSliderIndex: 0,
    productSliderIndexToShow: 0,
    recommandationsSliderIndex : 0,
    productData: [],
    isLoadingProduct: true,
    toggleThumbnailHover: false,
    zoomCarousel : false,
    zoomCarouselIndex :1,
    photoOfficielle : [],
 };


const productsReducer = (state = initialState, action = {}) => {
    switch (action.type) {


        case SAVE_PRODUCT_DATA_STATE : 
            return  {
                ...state,
                [action.state] : action.value,
            }

        case SLIDER_ZOOM_INDEX_TO_SHOW : 
            return {
                ...state,
                zoomCarouselIndex: action.value,
            }

        case TOGGLE_SLIDER_ZOOM_PRODUCT :
            return {
                ...state,
                zoomCarousel: action.value,
            }

        case TOGGLE_THUMBNAIL_PRODUCT_HOVER : 
            return {
                ...state, 
                toggleThumbnailHover : action.value,
            }

        case PRODUCT_SLIDER_INDEX_TO_SHOW : 
            return {
                ...state,
                productSliderIndexToShow: action.value,
            }

        case IS_LOADING_PRODUCT : 
            return {
                ...state,
                isLoadingProduct: action.value,
            }

        case SAVE_PRODUCT_DATA : 
            return {
                ...state,
                productData: action.value,
            }

        case RECOMMANDATIONS_SLIDER_CHANGE_INDEX : 
            return {
                ...state, 
                recommandationsSliderIndex: action.value,
            }

        case PRODUCT_SLIDER_CHANGE_INDEX : 
            return {
                ...state,
                productSliderIndex: action.value
            }

        case ACCORDION_MENU_ACTIF : 
            return {
                ...state,
                accordionMenuActif: action.value,

            }

        case TOGGLE_SELECT_PRODUCT_CHOICE : 
            return {
                ...state,
                isOpenSelectChoice: action.value,
            }
            case SELECT_VARIANT : 
            return {
                ...state,
                variantSelected: action.value,
            }

        default: 
            return state;
    }
};
export default productsReducer;