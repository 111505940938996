
//API for get country's name from ISO code Alpha2 
import countries from "i18n-iso-countries";

//Hooks
import { useDispatch, useSelector } from "react-redux";
//Actions
import { toggleModifyAddress, changeInputValue, putModifyAddress, loadingModifyAddress, errorModifyAddress } from "../../../../actions/userAction";
//Components
import InputSimple from "../../InputSimple/inputSimple";
import Spinner from "../../../Commons/Spinner/Spinner";
//Style
import "./ModifyAddress.scss";

//API for get country's name from ISO code Alpha2 
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

const ModifyAddress = () => {

    const dispatch = useDispatch();
    const id = useSelector((state) => state.user.adressIdModify);
    const firstName = useSelector((state) => state.user.firstNameAddressModify);
    const lastName = useSelector((state) => state.user.lastNameAddressModify);
    const society = useSelector((state) => state.user.societyAddressModify);
    const street = useSelector((state) => state.user.streetAddressModify);
    const complement = useSelector((state) => state.user.complementAddressModify);
    const postCode = useSelector((state) => state.user.postCodeAddressModify);
    const city = useSelector((state) => state.user.cityAddressModify);
    const telephone = useSelector((state) => state.user.telephoneAddressModify);
    const errorTelephone =  useSelector((state) => state.user.errorPatternTelephoneAddressModify);
    const country = useSelector((state) => state.user.countryAddressModify);
    const isLoading = useSelector((state) => state.user.loadingModifyAddress);
    const isError = useSelector((state) => state.user.errorModifyAddress);
    const countries = useSelector((state) => state.user.countries);

    return (
        <div className="modify-address-wrapper">
                { isLoading && (
                    <div className="spinner-wrapper">
                           <Spinner />
                    </div>
                )}   
              
            <div className="modify-popup-wrapper">
                <div className="moify-address-title">
                    Modifier l'adresse
                </div>
                { isError && (
                    <div className="error">
                        Une erreur s'est produite. Votre modification n'a pas pu être enregistrée.
                    </div>
                )}
                
                <form
                        action="#"
                        onSubmit={(event) => {
                            event.preventDefault();
                            // console.log("submit");
                            if((telephone.match(/[0-9]/g) || telephone.match(/[-+!?*$@%_]/g)) && (!telephone.match(/[A-Z]/g) && (!telephone.match(/[a-z]/g)))){
                                dispatch(changeInputValue(false, "errorPatternTelephoneAddressModify"));
                                dispatch(errorModifyAddress(false));
                                dispatch(loadingModifyAddress(true));
                                dispatch(putModifyAddress(id));
                            }else {
                                dispatch(changeInputValue(true, "errorPatternTelephoneAddressModify"));
                            }
                            
                            
                            
                        }}
                    >
                    <span
                        className="cross"
                        onClick={() => {
                            dispatch(errorModifyAddress(false));
                            dispatch(toggleModifyAddress(false));
                        }}
                    >
                        ╳
                    </span>
                    <InputSimple
                        inputName={"lastName"}
                        label={"Nom *"}
                        value={lastName}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            
                            dispatch(changeInputValue(event.target.value, "lastNameAddressModify"));
                        }}
                    />
                    <InputSimple
                        inputName={"firstName"}
                        label={"Prénom *"}
                        value={firstName}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                           
                            dispatch(changeInputValue(event.target.value, "firstNameAddressModify"));
                        }}
                    />
                    <InputSimple
                        inputName={"society"}
                        label={"Société"}
                        value={society}
                        type={"text"}
                        required={false}
                        onChange={(event) => {
                            
                            dispatch(changeInputValue(event.target.value, "societyAddressModify"));
                        }}
                    />

                    <InputSimple
                        inputName={"street"}
                        maxLength={35}
                        label={"Voie et numéro *"}
                        value={street}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                           
                            dispatch(changeInputValue(event.target.value, "streetAddressModify"));
                        }}
                    />
                    <InputSimple
                        inputName={"complement"}
                        maxLength={35}
                        label={"Complément"}
                        value={complement}
                        type={"text"}
                        required={false}
                        onChange={(event) => {
                           
                            dispatch(changeInputValue(event.target.value, "complementAddressModify"));
                        }}
                    />

                    <InputSimple
                        inputName={"postCode"}
                        label={"Code postal *"}
                        value={postCode}
                        type={"number"}
                        required={true}
                        onChange={(event) => {
                       
                            dispatch(changeInputValue(event.target.value, "postCodeAddressModify"));
                        }}
                    />

                    <InputSimple
                        inputName={"city"}
                        label={"Ville *"}
                        value={city}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                           
                             dispatch(changeInputValue(event.target.value, "cityAddressModify"));
                        }}
                    />
                    <InputSimple
                        error={errorTelephone}
                        inputName={"telephone"}
                        label={"Téléphone *"}
                        value={telephone}
                        type={"tel"}
                        required={true}
                        onChange={(event) => {
                            dispatch(changeInputValue(false, "errorPatternTelephoneAddressModify"));
                            dispatch(changeInputValue(event.target.value, "telephoneAddressModify"));
                        }}
                    />
                     <div className={`input-information ${errorTelephone ? "error" : ""}` }> Les lettres ne sont pas autorisées</div>
                    <div className="input-wrapper">
                            <label className='personal-label' htmlFor="first-name">
                                Pays *
                            </label>
                            <select
                                required
                                value={country}
                                name="country"
                                onChange={(event) => {
                                    dispatch(changeInputValue(event.target.value, "countryAddressModify"));
                                }}

                            >
                                 {/* <option value={null} >Veuillez sélectionner un pays</option> */}
                               
                                {countries.map(({name, code}) =>{
                                    
                                    if(code !== "EZ" && code !== "AC"){
                                        // console.log(code);
                                    return (
                                        <option key={code} value={code}>{name}</option>
                                    );
                                    }
                                  return;
                                }
                                )}
                               
{/*                                 
                                <option value="BE">{countries.getName("BE", "fr")}</option>
                                <option value="IT">{countries.getName("IT", "fr")}</option>
                                <option value="US">{countries.getName("US", "fr")}</option>
                                <option value="GB">{countries.getName("GB", "fr")}</option> */}
                            </select>
                         </div>
                    <button
                        className='submit-button'
                        type="submit"
                    >
                        Enregistrer
                    </button>
            </form>
        </div>
    </div>
    );
}
export default ModifyAddress;