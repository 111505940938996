// Components
import AccountAccess from "./AccountAcccess/AccountAccess";
import NavIcons from "../../../Commons/NavIcons/NavIcons";

//Hooks
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";


//Actions
import { countCart } from "../../../../actions/cartAction";
//Assets 
import cartLogo from '../../../../assets/cart.png';
import wishListLogo from '../../../../assets/empty_heart.png';
import loupe from '../../../../assets/loupe.png';
import user from "../../../../assets/user.png";
import player from "../../../../assets/player.png";

//Styles 
import './NavBar.scss';
import SearchBar from "./SearchBar/SearchBar";
import { NavLink } from "react-router-dom";
import OrderPopup from "../../../Cart/OrderPopup/OrderPopup";



const NavBar = () => {
    const dispatch = useDispatch();
    const isSearchBarOpen = useSelector((state) => state.prepage.toggleSearchbar);
    const isConnected = useSelector((state) => state.auth.userConnected);
    const wishList = useSelector((state) => state.user.wishList);
    const numberProductCart = useSelector((state) => state.cart.cartDataNumber);
    const cartItems = JSON.parse(sessionStorage.getItem("cart"));
    const orderPopup = useSelector((state) => state.common.orderPopup);

    useEffect(() => {
        if(cartItems !== null) {
            dispatch(countCart(cartItems.length))
        }
    },[ cartItems]);



    return (

        <div className="navBar">
            { !isSearchBarOpen && (
                
                <>
                
            {orderPopup && (
                    <OrderPopup/>
            )}
           {isConnected && (
            <AccountAccess logo={player} slug={"/mes-cours-et-documents"} />
           )}
            <AccountAccess logo={user} slug={"/mon-compte/mes-commandes"} />
            <NavLink className={"navlink-wrapper"}to={'/mon-compte/ma-liste-de-souhaits'}>
                <NavIcons 
                    logo={wishListLogo}
                    numberOfProduct={wishList.length}
                    styleColor={"wishList"}
                    altPicture={'Logo liste d\'envie'}
                />
            </NavLink>
            <NavLink className={"navlink-wrapper"} to={"/mon-panier"}>
                <NavIcons
                    logo={cartLogo}
                    numberOfProduct={numberProductCart}
                    styleColor={'cart'}
                    altPicture={'Logo liste d\'un pannier'}
                />
            </NavLink>
                </>
            )}
            <SearchBar
                logo={loupe}
                path={'/'}
                altPicture={'Logo d\'une loupe'}
                />
        </div>
    );
}
export default NavBar;