import Spinner from "../Spinner/Spinner"
import './RedirectWWW.scss';

const RedirectWWW = () => {
    // console.log(window.location);
    return (
        <div className="redirect-www">
           {window.location.replace("https://www.artesane.com" + window.location.pathname)}
           <Spinner/>
        </div>
    )
}

export default RedirectWWW;