export const TOGGLE_VARIANTS_CHOICE = "TOGGLE_VARIANTS_CHOICE";
export const SELECT_VARIANTS_CATALOGUE = "SELECT_VARIANTS_CATALOGUE";
export const VALIDATE_VARIANTS_ADD = "VALIDATE_VARIANTS_ADD";
export const COUNT_CART = "COUNT_CART";
export const CREATE_CART_DISCONNECTED = "CREATE_CART_DISCONNECTED";
export const GET_CART_DISCONNECTED =  "GET_CART_DISCONNECTED"
export const SAVE_CART_IN_STATE = "SAVE_CART_IN_STATE";
export const LOADING_ADD_CART = "LOADING_ADD_CART";
export const DELETE_ORDER_ITEM_CART = "DELETE_ORDER_ITEM_CART";
export const LOADING_DELETE_MODIFY_CART = "LOADING_DELETE_MODIFY_CART";
export const CREATE_CART_CONNECTED = "CREATE_CART_CONNECTED";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const VERIFY_CART = "VERIFY_CART";
export const GET_CART_CONNECTED = "GET_CART_CONNECT";
export const ADD_ORDER_ITEM_CONNECTED = "ADD_ORDER_ITEM_CONNECTED";
export const ASSOCIATIONS_SLIDER_CHANGE_INDEX = "ASSOCIATIONS_SLIDER_CHANGE_INDEX";
export const TOGGLE_BILLING_ADDRESS = "TOGGLE_BILLING_ADDRESS";
export const CHANGE_INPUT_ORDER = "CHANGE_INPUT_ORDER";
export const FETCH_PRODUCT_CART_ASSOCIATION = "FETCH_PRODUCT_CART_ASSOCIATION";
export const SAVE_CART_ASSOCIATION = "SAVE_CART_ASSOCIATION";
export const PUT_ADD_ADDRESS_ORDER = "PUT_ADD_ADDRESS_ORDER";
export const FETCH_PAYMENT_URL = "FETCH_PAYMENT_URL";
export const FETCH_PAYPLUG_REUSSITE = "FETCH_PAYPLUG_REUSSITE";
export const PATCH_SHIPPING_METHOD = "PATCH_SHIPPING_METHOD";
export const POST_COUPON_CODE = "POST_COUPON_CODE";
export const FETCH_ADJUSTMENTS = "FETCH_ADJUSTMENTS"; 
export const FETCH_METHODES_ENVOIE = "FETCH_METHODES_ENVOIE";
export const DELETE_COUPON_CODE = "DELETE_COUPON_CODE";



export const deleteCouponCode = (newValue) => ({
    type: DELETE_COUPON_CODE,
    value: newValue,
});

export const fetchMethodesEnvoie = (newValue) => ({
    type: FETCH_METHODES_ENVOIE,
    value: newValue,
});


export const fetchAdjustments = (orderId) => ({
    type: FETCH_ADJUSTMENTS,
    value: orderId,
});

export const postCouponCode = (newValue) => ({
    type: POST_COUPON_CODE,
    value: newValue,
});

export const patchShippingMethod = (shippingId) => ({
    type: PATCH_SHIPPING_METHOD,
    shippingId: shippingId,
});


export const fetchPayplugReussite = (newValue) => ({
    type: FETCH_PAYPLUG_REUSSITE,
    value: newValue,
});

export const fetchPaymentUrl = (newValue) => ({
    type: FETCH_PAYMENT_URL,
    value: newValue
})

export const putAddAddressOrder = (newValue) => ({
    type: PUT_ADD_ADDRESS_ORDER,
    value: newValue,
});


export const saveCartAssociation = (newValue) => ({
    type: SAVE_CART_ASSOCIATION,
    value: newValue,
});


export const fetchProductCartAssociation = (newValue) => ({
    type: FETCH_PRODUCT_CART_ASSOCIATION,
    value: newValue,
});

export const changeInputOrder = (newValue, stateName) => ({
    type: CHANGE_INPUT_ORDER,
    name: stateName,
    value: newValue,
});

export const toggleBillingAddress = (newValue) => ({
    type: TOGGLE_BILLING_ADDRESS,
    value: newValue,
});

export const associationsSliderChangeIndex = (newValue) => ({
    type: ASSOCIATIONS_SLIDER_CHANGE_INDEX,
    value: newValue,
});

export const addOrderItemConnected = (customer, variantId, productId, quantity) => ({
    type: ADD_ORDER_ITEM_CONNECTED,
    variantId: variantId,
    productId : productId,
    quantity: quantity,
    customer: customer
});

export const getCartConnected = (customerId, userToken) => ({
    type: GET_CART_CONNECTED,
    userToken: userToken,
    customer: customerId,
});


export const verifyCart = (cartToken, customerId, customerToken ) => ({
    type: VERIFY_CART,
    customer: customerId,
    cartToken : cartToken,
    customerToken:customerToken,
});

export const changeQuantity = (newValue, productId, variantId, token) => ({
    type: CHANGE_QUANTITY,
    quantity: newValue,
    productId: productId,
    variantId: variantId,
    token: token,
});

export const createCartConnected = (code, quantity) => ({
    type: CREATE_CART_CONNECTED,
    code: code,
    quantity : quantity,
});

export const loadingDeleteModifyCart =  (newValue) => ({
    type: LOADING_DELETE_MODIFY_CART,
    value : newValue,
});
 
export const deleteOrderItemCart = (productId, itemId, cartToken, user) => ({
    type: DELETE_ORDER_ITEM_CART,
    id: itemId,
    productId: productId,
    token: cartToken,
    user : user

});

export const loadingAddCart = (newValue) => ({
    type: LOADING_ADD_CART,
    value: newValue,
});

export const saveCartInState = (newValue) => ({
    type : SAVE_CART_IN_STATE,
    value: newValue,
});

export const getCartDisconnected = (tokenCart) => ({
    type : GET_CART_DISCONNECTED,
    value : tokenCart,
});

export const createCartDisconnected  = (productId, variantId, quantity,) => ({
    type : CREATE_CART_DISCONNECTED,
    productId: productId,
    variantId: variantId,
    quantity: quantity,


});

export const countCart = (newValue) => ({
    type : COUNT_CART,
    value: newValue,
});

export const validateVariantsAdd = (newValue) => ({
    type: VALIDATE_VARIANTS_ADD,
    value: newValue,
});


export const selectVariantsCatalogue = (newValue) => ({
    type : SELECT_VARIANTS_CATALOGUE,
    value: newValue,
});

export const toggleVariantsChoice = (newValue) => ({
    type: TOGGLE_VARIANTS_CHOICE,
    value: newValue,
});