  export class Artesane_Choix  // bloc bordeaux
 {
  constructor ( id, nom , donneesLues, critereParent ) // donnéesLues = débutant = 001010110 longueur du nombre total de produit récupérés par univers
   {  
      this.id        = id;
      this.nom         = nom;
  	   this.etat        = false;
      this.nombreProduits = 0;
      this.nombreProduitsBis = 0;
      this.nombreProduitsPrecedent = 0;
	
	
	 
      let longueur = donneesLues.length; //=> longueur des produits
	 
      this.etatDesProduits = new Array ( longueur );
      // this.etatDesProduitsBis = new Array ( longueur );
		   
      for ( let i = 0 ; i < longueur ; i++ )
      {
      this.etatDesProduits[i] = ( donneesLues.charAt ( i ) === '1' ); //test pour déterminer en fonction des 01 des bools
         if(this.etatDesProduits[i]) {
            this.nombreProduits ++ ;
            this.nombreProduitsBis ++ ;
         }
         // this.nombreProduitsBis ++;
      }

      this.critereParent = critereParent;
	      
   }
   
  getEtat ()
   {
    return this.etat;
   }
   
  setEtat ( nouvelEtat )
   {
    this.etat = nouvelEtat
   }
   
  getEtatDuProduit ( index )
   {
    return this.etatDesProduits[index];
   }
   
  setEtatDuProduit ( index , nouvelEtat )
   {
    this.etatDesProduits[index] = nouvelEtat;
   }
   
  addEtatDuProduit ( etat )
   {
    this.etatDesProduits.push ( etat );
   }
   
   
  changerEtatChoix ()
   {
      // console.log(' 1 **********Je change l\'etat de *************' + this.nom + this.etat + this);
	  this.etat = !(this.etat);
   //   console.log(' 2 **********Je change l\'etat de *************' + this.nom + this.etat + this);
     this.critereParent.calculerEtat();
     this.critereParent.calculerEtatDesProduits();
	 
   }
 }






 
 export class Artesane_Critere   // Bloc vert
 {
  constructor ( id, nom , taille, filtre, rang )
   {
      this.id              = id; 
      this.nom             = nom;
      this.rang            = rang;
	   this.etat            = true;
      this.filtre          =  filtre;  
	   this.etatDesProduits = new Array ( taille );   // tableau gris
      this.etatDesProduitsBis = new Array ( taille );
      this.nombreChoixDispo = 0;
      this.nombreChoixCoche = 0;
      // console.log("contructeur" + rang);
	
	for ( let i = 0 ; i < taille ; i++ )
	 {
	  this.etatDesProduits[i] = true;
     this.etatDesProduitsBis[i] = true;
	 }
	 
	this.choix = [];
   }
   
  calculerEtat ()
   {
    let aucun = true;
	  let tous  = true;
	
	for ( let i = 0 ; i < this.choix.length ; i++ )
	 {
      // console.log('je calcule les etat des choix s\'ils sont tous cochés ou non');
	  aucun = aucun && ( this.choix[i].getEtat() === false );
	  tous  = tous  && ( this.choix[i].getEtat() === true  );
	 }
    
	
	return aucun || tous;
   }
   
  getEtat ()
   {
    return this.etat; 
   }
   
  getEtatDuProduit ( index )
   {
    return this.etatDesProduits[index];
   }
   
  calculerEtatDesProduits ( )
   {
    for ( let index = 0 ; index < this.etatDesProduits.length ; index++ )
	 {
      let etatDuProduit = false;
   
      if ( this.etat )
	        {
       	     for ( let choix = 0 ; choix < this.choix.length ; choix++ )
	          {
			   etatDuProduit = etatDuProduit || this.choix[choix].getEtatDuProduit ( index );
              }
		    }
       else {
       	     for ( let choix = 0 ; choix < this.choix.length ; choix++ )
	          {
			   etatDuProduit = etatDuProduit || ( this.choix[choix].getEtat() && this.choix[choix].getEtatDuProduit ( index ) );
              }
   	        }
		  
  	  this.etatDesProduits[index] = etatDuProduit;
	 }
    this.filtre.calculerEtatDesProduits();
   } 

  addChoix ( id, nom , donneesLues )
   {
      let choix = new Artesane_Choix ( id, nom , donneesLues, this );
    this.choix.push (choix);

   //  if(this.choix.nombreProduits > 0) {
   //    console.log(this.choix.nombreProduits);
   //     this.nombreChoixDispo ++ ;
   //  }

   this.nombreChoixDispo ++ ;
   
   //  console.log( 'j\'ai ajouté le choix ' + nom, id);
    return choix;
    
   }
   
  changerPlusieursEtats ( chaine )
   {
    for ( let choix = 0 ; choix < this.choix.length ; choix++ )
	 {
	  this.choix[choix].setEtat ( chaine.charAt ( choix ) === '1' );
	 }
   }
 }





 
 
export class Artesane_Filtre   // bloc rouge
 {
  constructor ( nom , nbProduits ) //nom: nom du domaine //nombre produits total récupéré du domaine
   {
    this.nom             = nom;
	 this.etatDesProduits = new Array ( nbProduits );   // tableau cyan
    this.etatDesProduitsBis = new Array (nbProduits);
    this.pages = [];
    this.tableauTri = [];
    
   //  this.limit = 32;
   //  this.numPage = 0;
   //  this.debut = -1 ;
   //  this.fin = -1 ;
   //  this.compteur = 0;
	
	for ( let produit = 0 ; produit < nbProduits ; produit++ )
	 {
	  this.etatDesProduits[produit] = true;
     this.etatDesProduitsBis[produit] = true;
     this.tableauTri.push(produit);
	 }
	
    this.nbProduitsAAfficher = nbProduits;	
	 
	this.criteres = [];
   }
 

  addCritere ( id, nom, rang ) 
   {
      //  console.log("ajouter le critère " + nom + "au rang " + rang);

      // console.log(this.etatDesProduits.length);
    let critere = new Artesane_Critere ( id, nom , this.etatDesProduits.length, this, rang );
   // console.log("ajouter le critère " + critere.nom + "au rang " + critere.rang);
	this.criteres.push(critere);
   // console.log("j'ai ajouté un critère aux critères " + nom );
   // this.criteres.sort(function(a, b){return b.rang - a.rang});
   // this.criteres.forEach((item) => {
   //    // console.log(item.nom + " " + item.rang);
   // });

      return critere; 

   }
   
  calculerEtatDesProduits ( )
   {
      let limit = 5;
      let numPage = 1;
      let debut = -1 ;
      let fin = -1 ;
      let compteur = 0;
      
	
	for ( let produit = 0 ; produit < this.etatDesProduits.length ; produit++ )
	 {
	  this.etatDesProduits[produit] = true;
	 }
   
    for ( let critere=0 ; critere < this.criteres.length ; critere++)
	 {
	  this.criteres[critere].calculerEtatDesProduits ( );
	  
      for ( let produit = 0 ; produit < this.etatDesProduits.length ; produit++ )
	   {
	    this.etatDesProduits[produit] = this.etatDesProduits[produit] && ( this.criteres[critere].getEtatDuProduit ( produit ) ) ;
	   }
	 }

   //  console.log('coucou');
	 
	for ( let produit = 0 ; produit < this.etatDesProduits.length ; produit++ )
	 {
	  
	  if ( this.etatDesProduits[produit] )
	   {
	    this.nbProduitsAAfficher += 1;
       if ( debut === -1 ) {
         debut = produit;
         compteur = 1;
       } else  if( compteur === limit -1) {
            fin = produit;
            let limiteLocale = new Array(2);
            limiteLocale[0] = debut;
            limiteLocale[1] = fin;
            this.pages.push(limiteLocale);
            // console.log(numPage + debut + fin);
            compteur = 0;
            debut = -1;
            fin = -1;
            numPage ++;
            
       } else {
            compteur ++ ;
       }
	   }
	 }
   }
   

   // pour les raccourcis - megamenu header
  changerPlusieursEtats ( chaine )   // 001001-00101-000001110101100
   {
    let chaines = chaine.split ( "-" );
   
    for ( let critere=0 ; critere < this.criteres.length ; critere++)
	 {
	  this.criteres[critere].changerPlusieursEtats ( chaines[critere] );
	 }
	 
	this.calculerEtatDesProduits ( ); 
   }
   

  // afficher les produits si true/false
  getEtatDuProduit (index)
   {
      // console.log('je passe par getEtatDuProduit');
    return this.etatDesProduits[index] ;
   }
   
   //Pagination
  getNbProduitsAAfficher ()
   {
    return this.nbProduitsAAfficher;
   }
}


const artesaneCritere_calculEtat = (critere) => 
   {
   

    let aucun = true;
	 let tous  = true;
    let nb = critere.choix.length;
   //   console.log(nb);
	
	for ( let i = 0 ; i < nb ; i++ )
	 {
    
	  aucun = aucun && ( critere.choix[ i ].etat === false );
	  tous  = tous  && ( critere.choix[ i ].etat === true  );
	 }


    critere.etat = (aucun || tous);
   //  console.log('je passe dans artesaneCritere_calculEtat ' + critere.etat);

   };


   export const artesaneFiltre_calculerEtatDesProduits = (filtre, idCritereCourant) => 
   {
   
      filtre.pages = [];
      filtre.tableauTri = [];
	   filtre.nbProduitsAAfficher = 0;
	   filtre.nbProduitsAAfficher = 0;
      let limit = 32;
      let numPage = 1;
      let debut = -1 ;
      let fin = -1 ;
      let compteur = 0;
	
	for ( let produit = 0 ; produit < filtre.etatDesProduits.length ; produit++ )
	 {
	  filtre.etatDesProduits[produit] = true;
     filtre.etatDesProduitsBis[produit] = true;
	 }
   
    for ( let critere=0 ; critere < filtre.criteres.length ; critere++)
	 {
         for ( let produit = 0 ; produit < filtre.etatDesProduits.length ; produit++ )
         {
            filtre.etatDesProduits[produit] = filtre.etatDesProduits[produit] && ( filtre.criteres[critere].etatDesProduits[produit] ) ;
            
            if(filtre.criteres[critere].id == idCritereCourant ){
               filtre.etatDesProduitsBis[produit] = filtre.etatDesProduitsBis[produit] && ( filtre.criteres[critere].etatDesProduitsBis[produit] ) ;
            } else  {
               filtre.etatDesProduitsBis[produit] = filtre.etatDesProduitsBis[produit] && ( filtre.criteres[critere].etatDesProduits[produit] ) ;
            } 
         }
	 }
	 
	for ( let produit = 0 ; produit < filtre.etatDesProduits.length ; produit++ )
	 {
	  if ( filtre.etatDesProduits[produit] )
	   {
      //  indiceDernierProduitAffiche = produit;
	   //  filtre.nbProduitsAAfficher += 1;
       filtre.tableauTri.push(produit);


      //  if ( debut === -1 ) {
      //    debut = produit;
      //    compteur = 1;
      //    console.log('début =' + produit + " " + compteur);

      //  } else  if(( compteur === limit -1) || (produit === filtre.etatDesProduits.length -1)) {
      //       fin = produit;
      //       compteur ++;
      //       let limiteLocale = new Array(2);
      //       limiteLocale[0] = debut;
      //       limiteLocale[1] = fin;
      //       filtre.pages.push(limiteLocale);
      //       console.log("Fin prems " + debut +  " "  + fin + " "+ numPage + " " + compteur);
      //       compteur = 0;
      //       debut = -1;
      //       fin = -1;
      //       numPage ++;
            
      //  } else {
      //       compteur ++ ;
      //       console.log('milieu =' + produit + " " + compteur);
      //  }
	   }
	 }

    for(let produitAffiche = 0 ; produitAffiche < filtre.tableauTri.length ; produitAffiche ++ ) {


       if ( debut === -1 ) {
          debut = produitAffiche;
          compteur = 1;
         // console.log('début = ' + produitAffiche + " " + "nb produits = " +  compteur);

       }  else  if(( compteur === limit -1) || (produitAffiche === filtre.tableauTri.length -1)) {
               fin = produitAffiche;
               compteur ++;
               let limiteLocale = new Array(2);
               limiteLocale[0] = debut;
               limiteLocale[1] = fin;
               filtre.pages.push(limiteLocale);
               // console.log("Fin prems " + debut +  " "  + fin + " "+ numPage + " " + compteur);
               compteur = 0;
               debut = -1;
               fin = -1;
               numPage ++;
               
          } else {
               compteur ++ ;
               // console.log('milieu =' + produitAffiche + " " + compteur);
          }
        
    }
}


   const artesaneCritere_calculerEtatDesProduits = (critere, idCritereCourant) =>  
   {
      
            // console.log(critere.id + " " + idCritereCourant);
      // console.log('je passe dans artesaneCritere_calculerEtatDesProduits');
      let nb = critere.etatDesProduits.length;
      // console.log(critere);

    for ( let index = 0 ; index < nb ; index++ )
	 {
      // console.log('je passe dans boucle artesaneCritere_calculerEtatDesProduits')
      let etatDuProduit = false;
      let etatDuProduitBis = false;
   
      if ( critere.etat )
	        {
       	     for ( let choix = 0 ; choix < critere.choix.length ; choix++ )
	          {
               
			   etatDuProduit = etatDuProduit || critere.choix[choix].etatDesProduits[index];
               // console.log(etatDuProduit);
              }
		    }
       else {
       	     for ( let choix = 0 ; choix < critere.choix.length ; choix++ )
	          {
              
			      etatDuProduit = etatDuProduit || ( critere.choix[choix].etat && critere.choix[choix].etatDesProduits[index]);
               // console.log(critere.choix[choix].nom +  " " + index + " " + critere.choix[choix].etat + " " +  critere.choix [ choix ].etatDesProduits[index] + " => " + (critere.choix[choix].etat && critere.choix [ choix ].etatDesProduits[index]) + " " + etatDuProduit);
              }
   	        }
		  
  	  critere.etatDesProduits[index] = etatDuProduit;
      


       
           for ( let choix = 0 ; choix < critere.choix.length ; choix++ )
         {
           
        etatDuProduitBis = etatDuProduitBis || critere.choix[choix].etatDesProduits[index];
           // console.log(etatDuProduit);
          }
        critere.etatDesProduitsBis[index] = etatDuProduitBis;



      //   console.log(index +" " +  critere.etatDesProduitsBis[index]);

	 }
    artesaneFiltre_calculerEtatDesProduits(critere.filtre, idCritereCourant);
   } 


export const artesaneFiltre_ChangerEtatChoix = (filtreObjet, id, products,idCritereCourant, choixTri)  => {
   filtreObjet.criteres.forEach(critere => {
         critere.choix.forEach( choice => {

               if(id === choice.id) {
                  choice.etat = !choice.etat;
                  choice.critereParent.nombreChoixCoche = ( choice.etat && choice.critereParent.nombreChoixCoche + 1) || ( !choice.etat && choice.critereParent.nombreChoixCoche - 1);

                  // console.log( "je passe dans le if de artesaneFiltre_ChangerEtatChoix choix nouveau = " + choice.etat)

                  artesaneCritere_calculEtat(choice.critereParent);
                  artesaneCritere_calculerEtatDesProduits(choice.critereParent, idCritereCourant);
                  artesaneFiltre_calculerNombreProduits(filtreObjet, idCritereCourant);
               }
         })
   });

   artesaneTriChoix(filtreObjet.tableauTri, products, choixTri );
};


export const artesaneFiltre_ChoisirPrix = (filtreObjet, products, choixTri, prixMin, prixMax)  => {
   // console.log('je passe dans artesaneCritere_calculerEtatDesProduits' + " " + prixMin + " " + prixMax);
   // console.log(choixTri + " " + prixMin + " " + prixMax  );

   let idCritere = '001'; 

   filtreObjet.criteres.forEach(critere => {
      // console.log(idCritere + " " + critere.id.toString());
      if(idCritere === critere.id.toString().slice(critere.id.toString().length - 3)) {
         // console.log("- " + critere.id.toString());
         critere.etat = true; 
        
         let nb = critere.etatDesProduits.length;
            // console.log(critere);
            // console.log(nb);
          for ( let index = 0 ; index < nb ; index++ )
          {
              
             critere.etatDesProduits[index] = (prixMin <= products[index].variants[0].price/100 ) && ( products[index].variants[0].price/100 <= prixMax);
            //   console.log(products[index].id +" " + products[index].variants[0].price/100 + " " + critere.etatDesProduits[index] + " " + prixMin + " " + prixMax);
             
          }

          artesaneFiltre_calculerEtatDesProduits(critere.filtre);
          artesaneFiltre_calculerNombreProduits(filtreObjet, 0);
          artesaneTriChoix(filtreObjet.tableauTri, products, choixTri );
         


      }
   });

};




export const artesaneTriChoix = (tableauTri, products, choixTri) =>{
   if(choixTri === "PlusCher") {

      // console.log('PlusCher')
      tableauTri.sort(function(a, b){ return products[b].variants[0].price - products[a].variants[0].price });
 
   }

   if (choixTri === "MoinsCher") {
      // console.log('MoinsCher')
      tableauTri.sort(function(a, b){ return  products[a].variants[0].price - products[b].variants[0].price });
   }

   if(choixTri === "PlusRecent") {
      // console.log('PlusRecent')
     tableauTri.sort(function(a, b){ return  products[b].date1 -  products[a].date1 });
   }

   if(choixTri === "MoinsRecent") {
      // console.log('MoinsRecent')
      tableauTri.sort(function(a, b){ return products[a].date1 - products[b].date1});
   }
}


export const artesaneCritere_calculerNombreProduits = ( filtreObjet , critere, idCritereCourant) => {
   
   critere.nombreChoixDispo = 0 ;
   
 

   critere.choix.forEach( choice => {

      choice.nombreProduitsPrecedent = choice.nombreProduitsBis;
      choice.nombreProduitsBis = 0;
      choice.nombreProduits = 0;
     

      let different = (idCritereCourant != critere.id) ;

      // console.log(idCritereCourant);
      // console.log(critere.id);
      // console.log(different);

      for ( let i = 0 ; i < critere.etatDesProduits.length; i++ )
      {
         if(choice.etatDesProduits[i] && filtreObjet.etatDesProduits[i]) {
            choice.nombreProduits ++ ;
           
         }
         // console.log(critere.id);
         if(different) {
            if(choice.etatDesProduits[i] && filtreObjet.etatDesProduits[i]) {

            
            choice.nombreProduitsBis ++ ;
           
            } 

         } 
         else {
            if(choice.etatDesProduits[i] && filtreObjet.etatDesProduitsBis[i]) {
               // console.log(filtreObjet.etatDesProduitsBis[i]);
            
               choice.nombreProduitsBis ++ ;
              
               } 
         }
         // else if(filtreObjet.etatDesProduits[i]) {

            
         //    choice.nombreProduitsBis = 15;
           
         // }
         
         // if(choice.critereParent.id == critere.id) {

            
            // choice.nombreProduitsBis ++ ;
            // console.log(critere.id);
         // }
      }
      // if(!different) {
      //    choice.nombreProduitsBis = choice.nombreProduitsPrecedent;
      // }

      // console.log("choix " + choice.nom + " " + choice.nombreProduitsBis);

      if(choice.nombreProduits > 0) {
         critere.nombreChoixDispo ++ ;
      }
   });

   // console.log("critere " + critere.nom + " " + critere.nombreChoixDispo )
}

export const artesaneFiltre_calculerNombreProduits = (filtreObjet, idCritereCourant) => {
   filtreObjet.criteres.forEach( critere  => {
      artesaneCritere_calculerNombreProduits(filtreObjet, critere, idCritereCourant);
   })
}

export const artesaneCritere_changerPlusieursEtats = (critere, chaine, tableauIdNomChoix) => {

   let tableauLocalIdNomChoix = tableauIdNomChoix;

   // console.log(critere + " " + chaine + " " + critere.choix.length + " " + chaine.length);

   for ( let choice = 0 ; choice < critere.choix.length ; choice++ )
	 {
      if(choice < chaine.length && chaine.charAt ( choice ) === '1' ) {
         critere.choix[choice].etat = true;
         critere.nombreChoixCoche += 1;
         let object = {};
         object['nom'] = critere.choix[choice].nom;
         object['id'] = critere.choix[choice].id;
         tableauLocalIdNomChoix.push(object);
         // tableauLocalIdChoix.push(critere.choix[choice].id)
         // tableauLocalNomChoix.push(critere.choix[choice].nom);
      }
      else {
         critere.choix[choice].etat = false;
      }

      // console.log(critere.choix[choice] + " etat");
	 }

   artesaneCritere_calculEtat(critere);
   artesaneCritere_calculerEtatDesProduits(critere);
   // console.log(tableauLocalIdChoix + " " + "Critère");
   return tableauLocalIdNomChoix;
} 

export const artesaneFiltre_changerPlusieursEtats = (filtreObject, chaine, products, choixTri) => {

   let tableauIdNomChoix = [];

   // console.log(filtreObject);
   // console.log(chaine);
   // console.log(products);
   
    let chaines = chaine.split ( "-" );
   
    for ( let critere=0 ; critere < filtreObject.criteres.length ; critere++)
	 {
      tableauIdNomChoix = artesaneCritere_changerPlusieursEtats(filtreObject.criteres[critere], chaines[critere], tableauIdNomChoix );
      
	 }
	 
    artesaneFiltre_calculerEtatDesProduits(filtreObject);
    artesaneFiltre_calculerNombreProduits(filtreObject, 0);
    artesaneTriChoix(filtreObject.tableauTri, products, choixTri );
   //  console.log(tableauLocalIdChoix + " " + "Filtre");
    return tableauIdNomChoix;
   
} 