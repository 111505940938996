export const TOGGLE_SELECT_PRODUCT_CHOICE = 'TOGGLE_SELECT_PRODUCT_CHOICE';
export const SELECT_VARIANT = 'SELECT_VARIANT'; 
export const ACCORDION_MENU_ACTIF = "ACCORDION_MENU_ACTIF";
export const PRODUCT_SLIDER_CHANGE_INDEX = 'PRODUCT_SLIDER_CHANGE_INDEX';
export const RECOMMANDATIONS_SLIDER_CHANGE_INDEX = 'RECOMMANDATIONS_SLIDER_CHANGE_INDEX';
export const FETCH_PRODUCT_DATA =  'FETCH_PRODUCT_DATA';
export const SAVE_PRODUCT_DATA = 'SAVE_PRODUCT_DATA';
export const IS_LOADING_PRODUCT = 'IS_LOADING_PRODUCT';
export const PRODUCT_SLIDER_INDEX_TO_SHOW = 'PRODUCT_SLIDER_INDEX_TO_SHOW';
export const TOGGLE_THUMBNAIL_PRODUCT_HOVER = 'TOGGLE_THUMBNAIL_PRODUCT_HOVER';
export const TOGGLE_SLIDER_ZOOM_PRODUCT = 'TOGGLE_SLIDER_ZOOM_PRODUCT';
export const SLIDER_ZOOM_INDEX_TO_SHOW = 'SLIDER_ZOOM_INDEX_TO_SHOW';
export const SAVE_PRODUCT_DATA_STATE = "SAVE_PRODUCT_DATA_STATE";




export const saveProductDataState = (stateValue, newValue) => ({
    type: SAVE_PRODUCT_DATA_STATE,
    state : stateValue,
    value: newValue,
});

export const sliderZoomIndexToSHow = (newValue) => ({
    type: SLIDER_ZOOM_INDEX_TO_SHOW,
    value: newValue
});

export const toggleSliderZoomProduct = (newValue) => ({
    type: TOGGLE_SLIDER_ZOOM_PRODUCT,
    value: newValue,
});

export const toggleThumbnailProductHover = (newValue) => ({
    type: TOGGLE_THUMBNAIL_PRODUCT_HOVER,
    value: newValue,
});


export const productSliderIndexToShow = (newValue) => ({
    type: PRODUCT_SLIDER_INDEX_TO_SHOW,
    value: newValue,
});

export const isLoadingProduct = (newValue) => ({
    type: IS_LOADING_PRODUCT,
    value: newValue,
});

export const saveProductData = (newValue) => ({
    type: SAVE_PRODUCT_DATA,
    value: newValue,
});

export const fetchProductData = (newValue) => ({
    type: FETCH_PRODUCT_DATA,
    value: newValue,
});

export const recommandationsSliderChangeIndex = (newValue) => ({
    type: RECOMMANDATIONS_SLIDER_CHANGE_INDEX,
    value: newValue,
});

export const productSliderChangeIndex = (newValue) => ({
    type: PRODUCT_SLIDER_CHANGE_INDEX,
    value: newValue
});

export const accordionMenuActif = (newValue) => ({
    type: ACCORDION_MENU_ACTIF,
    value: newValue,
});

export const toggleSelectProductChoice = (newValue) => ({
    type: TOGGLE_SELECT_PRODUCT_CHOICE,
    value: newValue,
});

export const selectVariants = (newValue) => ({
    type : SELECT_VARIANT,
    value: newValue,
});