export const CHANGE_CURRENT_PROFESSORS_PAGE = 'CHANGE_CURRENT_PROFESSORS_PAGE';
export const FETCH_PROFESSEUR_CATALOGUE_DATA  = "FETCH_PROFESSEUR_CATALOGUE_DATA";
export const SAVE_PROFESSEUR_CATALOGUE_DATA = 'SAVE_PROFESSEUR_CATALOGUE_DATA';
export const FETCH_PROFESSEUR_PUCLIC_DATA = 'FETCH_PROFESSEUR_PUCLIC_DATA';
export const SAVE_PROFESSEUR_PUBLIC_DATA = 'SAVE_PROFESSEUR_PUBLIC_DATA';
export const FETCH_ALL_PROFESSORS = "FETCH_ALL_PROFESSORS";
export const SAVE_ALL_PROFESSORS = "SAVE_ALL_PROFESSORS";

export const saveAllProfessors = (newValue) => ({
    type: SAVE_ALL_PROFESSORS,
    value: newValue,
});

export const fetchAllProfessors = () => ({
    type : FETCH_ALL_PROFESSORS
});

export const fetchProfesseurPublicData = (slug) => ({
    type: FETCH_PROFESSEUR_PUCLIC_DATA,
    value: slug
});

export const saveProfesseurPublicData = (newValue) => ({
    type: SAVE_PROFESSEUR_PUBLIC_DATA,
    value: newValue,
    isLoading : false,
});

export const saveProfesseurCatalogueData = (newValue) => ({
    type: SAVE_PROFESSEUR_CATALOGUE_DATA,
    value: newValue,
    loading: false
});

export const fetchProfesseurCatalogueData = (universId) => ({
    type: FETCH_PROFESSEUR_CATALOGUE_DATA,
    value: universId,
});

export const changeCurrentProfessorsPage = (newValue) => ({
    type: CHANGE_CURRENT_PROFESSORS_PAGE,
    value: newValue,
});

