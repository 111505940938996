import { SAVE_COMMON_DATA_IN_STATE } from "../actions/commonAction";


const initialState = {
    professors: [],
    loadingProfessors : true,
    cookiesPopup : false,
    cookiesParameterPopup : false,
    functionnalsCookies: true,
    statisticsCookies: true,
    marketingCookies: true,
    searchBarGeneralWords : "",
    searchBarUniversWords: "",
    orderPopup : false,
    loadingGeneralSearchbar : true,
    loadingUniversSearchbar: true,
    searchGeneral : [],
    searchUnivers : [],
    modeMaintenance : false,
    loadingCoursIdeal : true,
    coursIdeal: [],
    coursIdealActual:[],
    coursIdealPrevious:[],
    coursIdealFind: [],
    loadingCoursIdealFind : true,
 };
 
 const commonReducer = (state = initialState, action = {}) => {
     switch (action.type) {

        case SAVE_COMMON_DATA_IN_STATE : 
            return {
                ...state,
                [action.property] : action.value,
            }
 
         default: 
             return state;
     }
 };
 export default commonReducer;