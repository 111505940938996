//Components
import Prepage from '../Prepage/Prepage';
import Home from '../Home/Home';
import Catalogue from '../Catalogue/Catalogue';
import Footer from '../Commons/Footer/Footer';
import FooterResponsive from '../Commons/FooterResponsive/FooterResponsive';
import Product from '../Product/Product';
import DeliveryAndReturn from '../Commons/DeliveryAndReturn/DeliveryAndReturn';
import QuiSommesNous from '../Commons/QuiSommesNous/QuiSommesNous';
import ProfesseurCatalogue from '../ProfesseurCatalogue/ProfesseurCatalogue';
import ProfesseurProfilPublic from '../ProfesseurProfilPublic/ProfesseurProfilPublic';
import UserAccount from '../UserAccount/UserAccount';

import PersonalInfos from '../UserAccount/PersonalInfos/PersonalInfos';
import Faq from '../Commons/FAQ/Faq';
import Contact from '../Commons/Contact/Contact';
import SecurePayment from '../Commons/SecurePayment/SecurePayment';
import ConditionsOffres from '../Commons/ConditionsOffres/ConditionsOffres';
import Partenaires from '../Commons/Partenaires/Partenaires';
import Subscriptions from '../UserAccount/Subscriptions/Subsciptions';
import Connexion from '../Auth/Connexion/Connexion';


//Action
import { toggleMenuBurger } from '../../actions/homeActions';

//Libraries
import MediaQuery from 'react-responsive';

//Actions
// import { toggleScroll } from '../../actions/homeActions';

// Style
import './App.scss';


//Hooks
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AllProfessors from '../Commons/AllProfessors/AllProfessors';
import Cgv from '../Commons/CGV/Cgv';
import PrivacyPolicy from '../Commons/PrivacyPolicy/PrivacyPolicy';
import LegalNotices from '../Commons/LegalNotices/LegalNotices';
import MyProfil from '../UserAccount/MyProfil/MyProfil';
import { checkSave, fetchUserData } from '../../actions/userAction';
import Addresses from '../UserAccount/Addresses/Addresses';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Wishlist from '../UserAccount/Wishlist/Wishlist';
import { changeInputAuthValue, userConnexion } from '../../actions/authAction';
import Inscription from '../Auth/Inscription/Inscription';
import MediaLibrary from '../MediaLibrary/MediaLibrary';
import MediaProducts from '../MediaLibrary/MediaProducts/MediaProducts';
import MediaDashboard from '../MediaLibrary/MediaDashboard/MediaDashboard';
import Player from '../MediaLibrary/Player/Player';
import Cart from '../Cart/Cart';
import { changeInputOrder, getCartConnected, getCartDisconnected } from '../../actions/cartAction';
import Order from '../Order/Order';
import Orders from '../UserAccount/Orders/Orders';
import Livraison from '../Order/Livraison/Livraison';
import PaymentValidate from '../Order/PaymentValidate/PaymentValidate';
import NotFound from '../Commons/NotFound/NotFound';
import DeconnectPopin from '../Auth/DeconnectPopIn/DeconnectPopin';
import ForgottenPassword from '../ForgottenPassword/ForgottenPassword';
import ConfirmRequest from '../ForgottenPassword/ConfirmRequest/ConfirmRequest';
import NewPassword from '../NewPassword/NewPassword';
import Cookies from '../Cookies/Cookies';
import { fetchMaintenanceMode, saveCommonDataInState } from '../../actions/commonAction';
import ResearchGeneral from '../ResearchGeneral/ResearchGeneral';
import ResearchUnivers from '../ResearchUnivers/ResearchUnivers';
import MaintenanceRoute from '../MaintenanceRoute/MaintenanceRoute';
import TopArrow from '../TopArrow/TopArrow';
import CoursIdeal from '../CoursIdeal/CoursIdeal';

//GOOGLE ANALYTICS
import ReactGA from 'react-ga';
import Version from '../Versions/Version';
import RedirectWWW from '../Commons/RedirectWWW/RedirectWWW';
const TRACKING_ID = "UA-78290342-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


function App() {

  const dispatch = useDispatch();
  const currentLocation = useLocation().pathname;
  const isUserConnected = useSelector((state) => state.auth.userConnected);
  const customerId = useSelector((state) => state.user.customerId);
  const mediaCulinaires = useSelector((state) => state.media.mediaArtsCulinaires);
  const mediaFil = useSelector((state) => state.media.mediaArtsDuFil);
  const mediaPlastiques = useSelector((state) => state.media.mediaArtsPlastiques);
  
  // console.log(isUserConnected);
  // const homeUniversActif = useSelector((state) => state.home.homeUniversActif);
  const isSave = useSelector((state) => state.user.isSave);
  
  const navigate = useNavigate();
  const isDeconnect = useSelector((state) => state.auth.isDeconnect);

  // const handleScroll = useCallback(() => {
    // console.log(Math.round(window.scrollY));
    // if(Math.round(window.scrollY) >= 50 ) {
    //   setTimeout(dispatch(toggleScroll(true)), 10000);
    // } else {
    //   setTimeout(dispatch(toggleScroll(false)), 10000);
    
    // }
    
    // dispatch(toggleScroll(window.scrollY >= 50));
// }, []);


// useEffect(() => {
  
//   console.log("Site réalisé avec ❤ par les équipes d'Artesane");
// },[]);


useEffect(() => {
  // console.log(window.location.pathname);
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
},[currentLocation]);


const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("user");
const cartToken = localStorage.getItem("cart");
const cookies = sessionStorage.getItem("cookies");
const cookiesPopup = useSelector((state) => state.common.cookiesPopup);
const maintenance = useSelector((state) => state.common.modeMaintenance);



// Le site est-il en maintenance?
useEffect(() => {
  dispatch(fetchMaintenanceMode());
  const interval = setInterval(() => {
    dispatch(fetchMaintenanceMode());
  }, 900000);

  return () => clearInterval(interval);
}, []);



useEffect(() => {
  if(!cookies) {
    dispatch(saveCommonDataInState("cookiesPopup", true));
  }else {
    dispatch(saveCommonDataInState("cookiesPopup", false));
  }

  
}, [cookies]);

useEffect(() => {
  if(token) {
    dispatch(changeInputAuthValue(email, "email"));
    dispatch(fetchUserData(email));
    dispatch(userConnexion(true));
    if(customerId !== 0) {
      dispatch(changeInputOrder(true, "loadingCart"));
      dispatch(getCartConnected(customerId, token));
    }
  } else if (cartToken && !token) {
    dispatch(changeInputOrder(true, "loadingCart"));
    dispatch(getCartDisconnected(cartToken));
  } else {
    dispatch(changeInputOrder(false, "loadingCart"));
  }

  
}, [token, dispatch, email, cartToken, customerId]);

// useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     return () => {
//         window.removeEventListener("scroll", handleScroll);
//     }
// }, [handleScroll]);

  useEffect(() => {
    // console.log("je suis srollTo 0 0");
    window.scrollTo({top: 0});
    dispatch((toggleMenuBurger(false)));
  }, [dispatch, currentLocation]);


  useEffect(() => {
    if(!isSave) {
      if(window.confirm('Êtes-vous sûr de vouloir quitter la page sans enregistrer vos modifications ? ')) {
        // console.log("ok");
        dispatch(fetchUserData(email));
        dispatch(checkSave(true));
      } else {
        dispatch(checkSave(true));
        navigate(-1);
        
      }
    }
    // console.log(currentLocation)
    }, [currentLocation, email, dispatch]);



  return (
    <div className="App">
      
      {/* {cookiesPopup && !maintenance && (
        <>
        <Cookies />
        </>
      )}
      {!maintenance && (
         <MediaQuery maxWidth={1000}>
            <TopArrow/>
        </MediaQuery> 
      
      )}
     
      {isDeconnect &&  !maintenance &&(
        <DeconnectPopin />
      )} */}
      <Routes>
        {/* {maintenance && (
          <Route path="/maintenance" element={<MaintenanceRoute/>}/>
        )} */}
      
      {/* <Route element={<ProtectedRoute redirectPath={"/maintenance"} isAllowed={ !maintenance} />}> */}
        
         <Route path="/version" element={<Version date={"29-03-2023 - 14h37  _redirection www "} />}/>
        {/*<Route path='/fr' element={<div> <Navigate to={"/"} replace={true}/> </div>} /> */}
        <Route path="/" element={<RedirectWWW/>}/>
        {/* <Route  path="/:univers" element={<Home />}/>
        <Route path="/recherche/:words" element={<ResearchGeneral/>}/>
        <Route path="/:univers/recherche/:words" element={<ResearchUnivers/>}/>
        <Route path="/:univers/catalogue/:currentFilters/:currentPage" element={ <Catalogue />}/>
        <Route path="/:univers/produit/:currentSlugProduct" element={ <Product />}/>
        <Route path="/:univers/professeurs/:currentPage" element={ <ProfesseurCatalogue />}/>
        <Route path="/:univers/professeur/:currentProfesseur" element={ <ProfesseurProfilPublic/>} />
        <Route path="/livraison-et-retours" element={ <DeliveryAndReturn />}/>
        <Route path="/faq" element={ <Faq />}/>
        <Route path="/contact" element={ <Contact />}/>
        <Route path="/paiement-securise" element={ <SecurePayment/>}/>
        <Route path="/qui-sommes-nous" element={ <QuiSommesNous />}/>
        <Route path="/cgv" element={ <Cgv />}/>
        <Route path="/politique-de-confidentialite" element={ <PrivacyPolicy />}/>
        <Route path="/mentions-legales" element={ <LegalNotices />}/>
        <Route path="/conditions-offres" element={ <ConditionsOffres />}/>
        <Route path="/:univers/nos-partenaires" element={ <Partenaires />}/>
        <Route path="/les-professeurs" element={ <AllProfessors />}/>
        <Route path="/connexion" element={<Connexion />}/>
        <Route path="/nouveau-mot-de-passe/:token" element={<NewPassword/>}/>
        <Route path="/mot-de-passe-oublie" element={<ForgottenPassword />}/>
        <Route path="/inscription" element={<Inscription/>}/>
        <Route path="/mon-panier" element={<Cart />}/>
        <Route path="/cours-ideal" element={<CoursIdeal />}/> */}
        {/* <Route element={<ProtectedRoute redirectPath={"/connexion"} isAllowed={ !!(token || isUserConnected)} />}>
          <Route path={"/commande"} element={<Order />}> 
            <Route path={"livraison"} element={<Livraison />}/>
          </Route>
          <Route  path='/paiement/:orderId/annulation' element={<div><Navigate to={"/mon-panier"} replace={true} /></div>}/>
          <Route path={"/paiement/:orderId/reussite"} element={<PaymentValidate />} />
          <Route path={"/mes-cours-et-documents/:collectionSlug/"} element={<MediaDashboard />} />
          <Route path={"/player/:videoSlug/:collectionId"} element={<Player/>} /> */}
          {/* <Route path={"/mes-cours-et-documents/arts-du-fil/:collectionSlug"}  element={<MediaDashboard />} /> */}
          {/* <Route path={"/mes-cours-et-documents/arts-plastiques/:collectionSlug"}  element={<MediaDashboard />} /> */}
          {/* <Route path={"/mes-cours-et-documents"} element={<MediaLibrary />}>
            <Route path={"arts-culinaires"} element={<MediaProducts data={mediaCulinaires}/>}/> 
            <Route path={"arts-du-fil"} element={<MediaProducts data={mediaFil} />}/>
            <Route path={"arts-plastiques"} element={<MediaProducts data={mediaPlastiques}/>}/>
          </Route>
          <Route path="/mon-compte" element={  <UserAccount/>}> */}
            {/* <Route element={<ProtectedRoute redirectPath={"/"} isAllowed={ !!isUserConnected} />}>  */}
              {/* <Route path={"mes-commandes"} element={<Orders />}/>
              <Route path={"mes-infos-personnelles"} element={<PersonalInfos/>} />
              <Route path={"mon-profil"} element={<MyProfil />} />
              <Route path={"mes-adresses"} element={<Addresses />} />
              <Route path={"mes-abonnements"} element={<Subscriptions />} />
              <Route path={"ma-liste-de-souhaits"} element={<Wishlist />}/>
          </Route>
        </Route> */}
        
        <Route path="*" element={<RedirectWWW/>} />
        {/* </Route> */}
      </Routes>
      {/* {!maintenance && (
        <>
          <MediaQuery minWidth={1000}>
            <Footer />  
        </MediaQuery>
        <MediaQuery maxWidth={1000}>
            <FooterResponsive />
      </MediaQuery> 
      </>
      )} */}
    
    </div>
  );
}

export default App;
