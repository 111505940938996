import { CHANGE_FILTERS,
        LOADING_COLLECTION,
        LOADING_MEDIA,
        SAVE_SINGLE_COLLECTION,
        SAVE_USER_MEDIA,
        TOGGLE_FILTERS_OPEN,
        SAVE_COURSE_ITEM,
        LOADING_COURSE_ITEM,
        SAVE_CHAPTERS,
        TOGGLE_CHAPTER_OPEN ,
        SAVE_TIME_VIDEO,
        TOGGLE_CHAT_BUTTON,
    } from "../actions/mediaAction";

const initialState = {
    mediaArtsCulinaires : [],
    mediaArtsDuFil: [],
    mediaArtsPlastiques: [],
    isloadingMedia: true,
    filtersOpen: false,
    packsEtParcours: false,
    documentsCours: false,
    videosCours: false,
    nbFilterActif: 0 ,
    loadingCollection: true,
    collection: [],
    singleCourse: [],
    loadingCourse: true,
    chapterOpen: false,
    timeInVideo : [],
    chatButton: false,
    light : true,


 };
 
 const mediaReducer = (state = initialState, action = {}) => {
     switch (action.type) {

        case TOGGLE_CHAT_BUTTON :
                return {
                    ...state,
                chatButton: action.value,
                }

        case SAVE_TIME_VIDEO : 
            return  {
                ...state, 
                timeInVideo: action.value,
            }

        case TOGGLE_CHAPTER_OPEN :
                return {
                    ...state,
                    chapterOpen: action.value
                }

        case SAVE_CHAPTERS : 
            return {
                ...state,
                courseChapter: action.value,
            }

        case LOADING_COURSE_ITEM : 
            return {
                ...state,
                loadingCourse: action.value,
            }

        case SAVE_COURSE_ITEM :
             return {
                ...state,
                singleCourse: action.value,
             }

        case SAVE_SINGLE_COLLECTION:
            return {
                ...state,
                collection : action.value,
            }

        case LOADING_COLLECTION :
            return {
                ...state,
                loadingCollection: action.value,
            }
        case CHANGE_FILTERS : 
            return {
                ...state,
                [action.name] : action.value,
                nbFilterActif : action.filterNb,
            }

        case TOGGLE_FILTERS_OPEN : 
            return {
                ...state,
                filtersOpen: action.value,
            }

        case SAVE_USER_MEDIA : 
            return {
                ...state,
                [action.name]: action.value,
            }
        case LOADING_MEDIA : 
            return {
                ...state,
                isloadingMedia: action.value,
            }
     
         default: 
             return state;
     }
 };
 export default mediaReducer;