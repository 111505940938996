//Actions
import { 
    SAVE_CATALOGUE_LESSONS_IN_STATE,
    TOGGLE_IS_FILTER_ACTIF,
    TOGGLE_FILTER_NAME_ACTIF,
    TOGGLE_IS_SORT_ACTIF,
    SAVE_CURRENT_PAGE_CATALOGUE,
    SAVE_FILTERS,
    TOGGLE_NUMBER_FILTERS_VISIBLE,
    ADD_FILTERS_CHECKBOX,
    REMOVE_FILTERS_CHECKBOX,
    REMOVE_ALL_FILTERS,
    TOGGLE_LOADING,
    CHANGE_SORT_ACTIF_VALUE,
    CHANGE_PRICE_SLIDER_VALUE,
    TOGGLE_404,
    SAVE_PRODUCT_VARIANTS,
    SAVE_FILTER_REF,
    SAVE_NUMBER_PRODUCT,
    } from "../actions/catalogueAction";




const initialState = {
    catalogueData : [],
    currentFilterActif : " ",
    isFilterActif : false,
    isSortActif : false,
    isTriActif : false,
    filterNameActif: '',
    numberOfItems: 0,
    filters: [],
    filterVisible : 5,
    filtersCheckboxInput : [],
    filterCheckBoxInputName:[],
    filterObject : {},
    loaderLessons: true,
    sortActifValue : 'PlusRecent',
    currentPage: 1,
    indexDebutProduit: 0,
    indexFinProduit : 32,
    priceValue : [0, 600],
    pageNotFound: false,
    productVariants : [],
    numberProduct : [],
 };
 
 const catalogueReducer = (state = initialState, action = {}) => {
     switch (action.type) {


        case SAVE_NUMBER_PRODUCT : 
            return {
                ...state,
                numberProduct: action.value,
            }

        case SAVE_PRODUCT_VARIANTS :
            return  {
                ...state,
                productVariants: action.value,
            }

        case TOGGLE_404 :
            return {
                ...state,
                pageNotFound: action.value,
            }

        case CHANGE_PRICE_SLIDER_VALUE : 
            return {
                ...state,
                priceValue: action.value,
            }

        case CHANGE_SORT_ACTIF_VALUE : 
            return {
                ...state,
                sortActifValue: action.value,
            }



        case TOGGLE_LOADING : 
            return {
                ...state,
                loaderLessons: action.value,
            }

        case REMOVE_ALL_FILTERS : 
            return {
                ...state,
                filtersCheckboxInput: [],
                filterCheckBoxInputName : [],
            }

        case REMOVE_FILTERS_CHECKBOX : 
            return {
                ...state,
                filtersCheckboxInput : [...state.filtersCheckboxInput].filter((item) => (item !== action.value)),
                filterCheckBoxInputName:  [...state.filterCheckBoxInputName].filter((item) => (item !== action.name)),
            }

        case ADD_FILTERS_CHECKBOX :{ 
        return {
            ...state,
            filtersCheckboxInput : [...state.filtersCheckboxInput, action.value],
            filterCheckBoxInputName : [...state.filterCheckBoxInputName, action.name],
        }}

        case TOGGLE_NUMBER_FILTERS_VISIBLE : 
        return {
            ...state,
            filterVisible: action.value,
        }
        case SAVE_FILTERS : 
        return {
            ...state,
            filters: action.value,
            filterObject: action.object,

        }
        case SAVE_CATALOGUE_LESSONS_IN_STATE : 
            return {
                ...state,
                catalogueData: [...action.value],
                numberOfItems: action.number,
            }
        case SAVE_CURRENT_PAGE_CATALOGUE : 
        return {
            ...state,
            currentPage: action.value,
        }
        case TOGGLE_IS_FILTER_ACTIF : 
            return {
                ...state,
                isFilterActif: action.value,
            }
        case TOGGLE_IS_SORT_ACTIF : 
            return {
                ...state,
                isSortActif: action.value,
            }
        case TOGGLE_FILTER_NAME_ACTIF : 
        return {
            ...state,
            filterNameActif: action.name,
        }

         default: 
             return state;
     }
 };
 export default catalogueReducer;