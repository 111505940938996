import { CHANGE_CURRENT_PROFESSORS_PAGE, SAVE_PROFESSEUR_CATALOGUE_DATA, SAVE_PROFESSEUR_PUBLIC_DATA, SAVE_ALL_PROFESSORS } from "../actions/professorsAction";

const initialState = {
    catalogueCurrentPage: 1,
    professeurCatalogueData : [],
    isLoadingProfesseurCatalogue : true,
    professeurPublicData : [],
    isLoadingProfesseurPublicData : true,
    isLoadingAllProfessors : true,
    allProfessors : [],

 };
 
 const professorsReducer = (state = initialState, action = {}) => {
     switch (action.type) {

        case SAVE_ALL_PROFESSORS : 
            return {
                ...state,
                isLoadingAllProfessors: false,
                allProfessors: action.value,

            }

        case SAVE_PROFESSEUR_PUBLIC_DATA : 
            return {
                ...state, 
                professeurPublicData : action.value,
                isLoadingProfesseurPublicData: action.isLoading,
            }

        case SAVE_PROFESSEUR_CATALOGUE_DATA : 
            return {
                ...state,
                professeurCatalogueData: action.value,
                isLoadingProfesseurCatalogue: action.loading,
            }

        case CHANGE_CURRENT_PROFESSORS_PAGE : 
            return {
                ...state,
                catalogueCurrentPage: action.value,
            }
 
         default: 
             return state;
     }
 };
 export default professorsReducer;