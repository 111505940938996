
//Libraries
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
// import OutsideClickHandler from 'react-outside-click-handler';
//Hooks
import { useDispatch, useSelector } from 'react-redux/es/exports';

import { artesaneFiltre_ChoisirPrix } from '../../../../../../selectors/ArteseFilter';

//Action
import { changePriceSliderValue, toggleFilterNameActif } from '../../../../../../actions/catalogueAction';


//Styles
import './FilterChoiceSlider.scss';

function valuetext(value) {
    return `${value}€`;
  }
  


const FilterChoiceSlider = () => {
    const dispatch = useDispatch();
    const valueMin = useSelector((state) => state.catalogue.priceValue[0]);
    const valueMax = useSelector((state) => state.catalogue.priceValue[1]);
    const value = useSelector((state) => state.catalogue.priceValue);
    // console.log(valueMin +" "+ valueMax);
    const filtreObjet = useSelector((state) => state.catalogue.filterObject);
    const lessonsData = useSelector((state) => state.catalogue.catalogueData);
    const sortActifValue = useSelector((state) => state.catalogue.sortActifValue);

 

    return (
        <div 
        className='filter-choice-slider-wrapper'>
            
            <Box sx={{ width: 300 }}>
                
                <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    onChange={(event) => {
                        
                        
                        // console.log(event.target.value + "slider ");
                        artesaneFiltre_ChoisirPrix(filtreObjet, lessonsData, sortActifValue, event.target.value[0], event.target.value[1]);
                        dispatch(changePriceSliderValue(event.target.value));
                       
                        
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    min={0}
                    max={600}
                />
                <span className='filter-choice-slider-min-price'>
                    0 €
                </span>
                <span className='filter-choice-slider-max-price'>
                    600 €
                </span>
            </Box>
          
        </div>
    );
}

export default FilterChoiceSlider;