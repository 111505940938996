//Styles
import './Logo.scss';

//Hooks
import { useSelector } from 'react-redux';

// Assets 
import logo from '../../../assets/logo.png';

const Logo = () => {

    const isScrolling = useSelector((state) => state.home.scroll);

    return(
        <div className={isScrolling ? "logo-scroll" : "logo"}> 
            <img src={logo} alt="Logo du site Artesane" />
        </div>
    
    );
}

export default Logo;