import { useDispatch } from "react-redux";
import { saveCommonDataInState } from "../../../actions/commonAction";
import "./OrderPopup.scss";

const OrderPopup = () => {
    const dispatch = useDispatch();
    return (
        <div className="order-popup-wrapper">
            <span
            onClick={() => {dispatch(saveCommonDataInState("orderPopup", false))}}
            className="cross">╳</span>
            <div className="popup-wrapper">
                Vous aviez des articles déjà présents dans votre panier utilisateur. Nous venons de les ajouter à votre nouveau panier.
            </div>
        </div>
    );
}

export default OrderPopup;