export const USER_CONNECTION = 'USER_CONNECTION';
export const TOGGLE_POPUP_VERIFIED_EMAIL = "TOGGLE_POPUP_VERIFIED_EMAIL";
export const POST_EMAIL_VERIFICATION = "POST_EMAIL_VERIFICATION";
export const CHANGE_INPUT_AUTH_VALUE = "CHANGE_INPUT_AUTH_VALUE";
export const IS_ERROR_PATTERN_EMAIL_VERIFICATION = "IS_ERROR_PATTERN_EMAIL_VERIFICATION";
export const IS_EMAIL_EXIST = "IS_EMAIL_EXIST";
export const VISIBILITY_AUTH_PASSWORD = "VISIBILITY_AUTH_PASSWORD";
export const LOADING_CONNEXION = "LOADING_CONNEXION";
export const POST_CONNEXION = "POST_CONNEXION";
export const ERROR_CONNEXION = "ERROR_CONNEXION";
export const LOADING_REGISTRATION = "LOADING_REGISTRATION";
export const POST_REGISTRATION = "POST_REGISTRATION";
export const REDIRECT_TO_ORDER = "REDIRECT_TO_ORDER";
export const POST_MAIL_CONFIRMATION = "POST_MAIL_CONFIRMATION";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const MODIFY_RESET_PASSWORD = "MODIFY_RESET_PASSWORD";


export const modifyResetPassword = (token, newPassword, confirmPassword) => ({
    type: MODIFY_RESET_PASSWORD,
    value: token,
    newPassword: newPassword,
    confirmPassword: confirmPassword,
});


export const resetPassword = (newValue)  => ({
    type: RESET_PASSWORD,
    value: newValue,
});


export const postMailConfirmation = (newValue) => ({
    type: POST_MAIL_CONFIRMATION,
    value: newValue
});


export const redirectToOrder = (newValue) => ({
    type: REDIRECT_TO_ORDER,
    value: newValue,
});


export const postRegistration = () => ({
    type: POST_REGISTRATION,

});

export const loadingRegistration = (newValue) => ({
    type: LOADING_REGISTRATION,
    value: newValue,
});

export const errorConnexion = (newValue) => ({
    type : ERROR_CONNEXION,
    value: newValue,

});

export const postConnexion = (password, email, stayConnected) => ({
    type: POST_CONNEXION,
    password: password,
    email: email,
    stayConnected: stayConnected,
});


export const loadingConnexion = (newValue) => ({
    type : LOADING_CONNEXION,
    value: newValue,
});

export const visibilityAuthPassword = (newValue) => ({
    type: VISIBILITY_AUTH_PASSWORD,
    value: newValue
});


export const isEmailExist = (newValue) => ({
    type: IS_EMAIL_EXIST,
    value: newValue,
});

export const isErrorPatternEmailVerification = (newValue) => ({
    type: IS_ERROR_PATTERN_EMAIL_VERIFICATION,
    value: newValue,
})


export const changeInputAuthValue = (newValue, inputName) => ({
    type: CHANGE_INPUT_AUTH_VALUE,
    value: newValue,
    name: inputName,
});

export const postEmailVerification = (newValue) => ({
    type : POST_EMAIL_VERIFICATION,
    value: newValue,
});

export const togglePopupVerifiedEmail = (newValue) => ({
    type: TOGGLE_POPUP_VERIFIED_EMAIL,
    value: newValue
});

export const userConnexion = (newValue) => ({
    type: USER_CONNECTION,
    value: newValue,
});