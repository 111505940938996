import CircularProgress from '@mui/material/CircularProgress';
import "./Spinner.scss";
const Spinner = () => {
    return (
        <div className='spinner-wrapper'>
             <CircularProgress color='inherit'/>
        </div>
       
    );
}

export default Spinner;