import Header from "../Prepage/Header/Header";
import './Version.scss';

const Version = ({date}) => {
    return (
        <div>
            <Header />
            <div className="version-wrapper">
                Version {date}
            </div>
        </div>
    );
}

export default Version;