
//Actions
import { changeSearchbar, toggleSearchbar } from '../../../../../actions/prepageAction';
import { getGeneralSearchbar, saveCommonDataInState } from '../../../../../actions/commonAction';
import { useDispatch, useSelector } from "react-redux";

//Styles 
import './SearchBar.scss';
import { useNavigate } from 'react-router-dom';


const SearchBar = ({logo, altPicture, styleColor, numberOfProduct, title}) => { 

    const dispatch = useDispatch();
    const isSearchBarOpen = useSelector((state) => state.prepage.toggleSearchbar);
    const inputValue = useSelector((state) => state.prepage.inputSearchbar);
    const navigate = useNavigate();
    const loadingSearchbar = useSelector((state) => state.common.loadingGeneralSearchbar);

return (
    <>
        { !isSearchBarOpen && (
        <div 
            className='navIcon-wrapper'
            onClick={ () => (
                dispatch(toggleSearchbar(true))
            )}
        >
            <div className='navicon-logo-wrapper'>
                <img className='navIconLogo' src={logo} alt={altPicture} />
                <span className={`product-number ${styleColor}`}> {numberOfProduct}</span>
            </div>
            <div className='navIcon-title'>{title}</div>
        </div>
        )}

        { isSearchBarOpen && (
             <div className="searchBar">
                <form 
                    className='searchBar-form' 
                    onSubmit={(event) => {
                        event.preventDefault();
                        if(!loadingSearchbar) {
                            dispatch(saveCommonDataInState("loadingGeneralSearchbar", true));
                        }
                        dispatch(getGeneralSearchbar(inputValue));
                        dispatch(saveCommonDataInState("searchBarGeneralWords", inputValue))
                        navigate(`/recherche/${inputValue}`);
                    }}
                >
                    <input
                        id='search'
                        name='search'
                        className='searchBar-input'
                        type="search"
                        placeholder='RECHERCHER...'
                        value={inputValue}
                        onChange={(event) => {
                            dispatch(changeSearchbar(event.target.value));
                        }}
                    />
                    <div 
                        className='searchBar-icon'
                        onClick={() => (
                            dispatch(toggleSearchbar(false))
                        )}
                    >
                            x
                    </div>
                </form>
         </div>
        )}
    </>
        
    )
}

export default SearchBar;
