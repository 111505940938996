
//Data
import { homeSliderProduct } from '../data/selectProductHomeSliderByTaxon.js';
import { clientsAdives } from '../data/clientsAdvices.js';
import { homeCatalogueTaxons } from '../data/homeCatalogueTaxons.js';


//Actions
import {
        TOGGLE_BURGER_MENU,
        TOGGLE_DROPDOWN_LESSON,
        TOGGLE_DROPDOWN_USER,
        TOGGLE_ACTIF,
        TOGGLE_ACCORDION,
        TOGGLE_SCROLL,
        CHANGE_SEARCH_BAR_HOME,
        CHANGE_INPUT_NEWSLETTER,
        SAVE_HOME_DATA_IN_STATE,
        TOGGLE_TAXONS_ACTIF,
        TOGGLE_UNDER_TAXONS_ACTIF,
        SAVE_HOME_UNIVERS_LOCATION_ACTIF,
        TOGGLE_SOUL_BUTTON_VOIR_PLUS,
        SAVE_MEGA_MENU,
        TOGGLE_MEGA_MENU_ACTIF,
        IS_LOADING_MEGA_MENU,
        TOP_SLIDER_CHANGE_INDEX,
        LESSON_SLIDER_CHANGE_INDEX,
        SAVE_LESSONS_SLIDER,
        SAVE_TOP_SLIDER_ITEMS,
        } from "../actions/homeActions"; 

const initialState = {
    homeUniversActif : "",
    faqsData: [],
    homeSliderActif : 'cuisine',
    selectProductSliderHomeByTaxon: homeSliderProduct,
    topSlider : [],
    professors: [],
    navData: [],
    isLoadingMegaMenu: true,
    tableauCorrespondance: [],
    megaMenuActif: "",
    clientsAdvices: clientsAdives,
    homeCatalogueTaxons: homeCatalogueTaxons,
    accordionExpended : false,
    accordionNumberItem: 8,
    scroll: true,
    // Inputs
    inputHomeSearchBar: '',
    inputHomeNewsletter: '',
    //Burger
    isOpenBurgerMenu : false,
    toggleTaxonsActif: '',
    toggleUnderTaxonsActif: '',
    //Soul
    isOpenToggleSoulButton : false,
    //Slider
    lessonsSlider: [],
    topSliderIndexActif: 0,
    lessonSliderIndexActif:0,

};

const homeReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SAVE_TOP_SLIDER_ITEMS : 
            return {
                ...state,
                topSlider: action.value,
            }
        case SAVE_LESSONS_SLIDER : 
            return {
                ...state,
                lessonsSlider: action.value,
            }

        case LESSON_SLIDER_CHANGE_INDEX : 
            return {
                ...state,
                lessonSliderIndexActif: action.value,
            }

        case TOP_SLIDER_CHANGE_INDEX : 
            return {
                ...state,
                topSliderIndexActif: action.value,
            }

        case IS_LOADING_MEGA_MENU : 
            return {
                ...state,
                isLoadingMegaMenu: action.value,
            }

        case TOGGLE_MEGA_MENU_ACTIF :
            return {
                ...state,
                megaMenuActif: action.value,
            }
        case SAVE_MEGA_MENU :
            return {
                ...state,
                navData: action.value,
                tableauCorrespondance: action.tableau,
            }

        case TOGGLE_SOUL_BUTTON_VOIR_PLUS :
            return {
                ...state, 
                isOpenToggleSoulButton: action.value,
            }
        case TOGGLE_BURGER_MENU :
            return {
                ...state, 
                isOpenBurgerMenu: action.value,
            }
        case SAVE_HOME_DATA_IN_STATE :
            return {
                ...state, 
                [action.property]: action.value,
            }
        case TOGGLE_DROPDOWN_LESSON :
            return {
                ...state,
                dropDownMenuLesson: action.value,
            }
        case TOGGLE_DROPDOWN_USER :
            return {
                ...state,
                dropDownMenuUser: action.value,
            } 
        
        case TOGGLE_ACTIF :
            return {
                ...state,
                homeSliderActif: action.value,
            }
        case TOGGLE_ACCORDION :
            return {
                ...state,
                accordionExpended: action.value,
                accordionNumberItem: action.number,
            }
        case TOGGLE_SCROLL :
            return {
                ...state,
                scroll: action.value,
            }
        case CHANGE_SEARCH_BAR_HOME :
            return {
                ...state,
                inputHomeSearchBar: action.value,
            }
        case CHANGE_INPUT_NEWSLETTER :
            return {
                ...state,
                inputHomeNewsletter: action.value,
            }
        case TOGGLE_TAXONS_ACTIF :
            return {
                ...state,
                toggleTaxonsActif: action.value,
            }
        case TOGGLE_UNDER_TAXONS_ACTIF:
            return {
                ...state,
                toggleUnderTaxonsActif: action.value,
            }
        case SAVE_HOME_UNIVERS_LOCATION_ACTIF:
            return {
                ...state,
                homeUniversActif: action.value,
            }
        

        default: 
            return state;
    }
};
export default homeReducer;