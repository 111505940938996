export const SAVE_COMMON_DATA_IN_STATE = 'SAVE_COMMON_DATA_IN_STATE';
export const GET_GENERAL_SEARCH_BAR = "GET_GENERAL_SEARCH_BAR";
export const GET_UNIVERS_SEARCH_BAR = "GET_UNIVERS_SEARCH_BAR";
export const FETCH_MAINTENANCE_MODE = "FETCH_MAINTENANCE_MODE";
export const FETCH_COURS_IDEAL = "FETCH_COURS_IDEAL";
export const FETCH_PRODUCTS_COURS_IDEAL = "FETCH_PRODUCTS_COURS_IDEAL";






export const fetchProductsCoursIdeal = (products) => ({
    type: FETCH_PRODUCTS_COURS_IDEAL,
    products : products
});

export const fetchCoursIdeal = () => ({
    type: FETCH_COURS_IDEAL,
});

export const fetchMaintenanceMode = () => ({
    type: FETCH_MAINTENANCE_MODE,
    
});


export const getGeneralSearchbar = (words)  => ({
    type: GET_GENERAL_SEARCH_BAR,
    words: words
});

export const getUniversSearchbar = (words, univers) => ({
    type : GET_UNIVERS_SEARCH_BAR,
    words: words,
    univers: univers
});

export const saveCommonDataInState = (stateProperty, newvalue) => ({
    type: SAVE_COMMON_DATA_IN_STATE,
    value: newvalue,
    property: stateProperty,
});



